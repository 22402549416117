import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import GhostButtonA from 'src/components/GhostButtonA';

import theme from 'src/styles/theme';
import media from 'src/styles/media';
import vw from 'src/styles/utils';

import CompassIcon from 'src/assets/images/floorplans/north.svg';

const Accordion = ({
  unitId,
  display,
  type,
  sqft,
  unitPng,
  plateThumb,
  pdfLink,
  expanded,
  onClick,
}) => {
  return (
    <Intl>
      {(t) => (
        <Root>
          <Title
            onClick={() => onClick(unitId)}
            className={expanded && 'selected'}
          >
            <PlanId>{display}</PlanId>
            <TitleColumn>
              <PlanType>{type}</PlanType>
              <PlanArea>
                {sqft} <AreaUnit>aprox. sq ft</AreaUnit>
              </PlanArea>
            </TitleColumn>
          </Title>

          <Body expanded={expanded}>
            <PlanImage width="600" src={unitPng} />
            <PlanMeta>
              <ThumbCompass>
                <PlateThumb
                  heigh="76"
                  src={plateThumb}
                  width="200"
                  height="53"
                />
                <Compass src={CompassIcon} width="14" height="14" />
              </ThumbCompass>
              <GhostButtonA
                href={pdfLink}
                download
                text={t.floorplans.downloadPlan}
              />
            </PlanMeta>
          </Body>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  border-bottom: 1px solid #4b4e52;
`;

const Title = styled.div`
  font-size: 24px;
  display: flex;
  cursor: pointer;
  color: ${theme.color.cc_grey};
  align-items: center;
  justify-content: space-between;
  ${vw('height', 74, 89)}

  &:hover,
  &:active,
  &:focus,
  &.selected {
    color: ${theme.color.cc_off_white_bg};
    background-color: ${theme.color.cc_blue};
  }
`;

const TitleColumn = styled.div`
  width: 72%;
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
  }
`;

const PlanId = styled.p`
  text-transform: uppercase;
  font-family: ${theme.font.family};
  ${vw('font-size', 48)}
  ${vw('line-height', 56)}
  ${vw('letter-spacing', 3.2)}
  text-align: center;
  text-transform: uppercase;
  width: 28%;

  @media ${media.tablet} {
    text-align: center;
  }
`;

const PlanType = styled.p`
  font-family: ${theme.font.sans_serif_family};
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  ${vw('font-size', 12, 16)}
  ${vw('line-height', 16, 28)}
  ${vw('letter-spacing', 2, 4)}

  @media ${media.tablet} {
    flex: 2;
  }
`;
const PlanArea = styled.p`
  font-family: ${theme.font.sans_serif_family};
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  ${vw('font-size', 12, 16)}
  ${vw('line-height', 16, 28)}
  ${vw('letter-spacing', 2, 4)}

  @media ${media.tablet} {
    flex: 1;
  }
`;

const Body = styled.div`
  text-align: center;
  height: ${(props) => (props.expanded ? 'auto' : 0)};
  display: ${(props) => (props.expanded ? 'block' : 'none')};
  overflow-y: hidden;
  transition: all 1s;
  background-color: ${theme.color.cc_off_white};
  ${vw('padding-top', 16, 40)}
  ${vw('padding-right', 16, 40)}
  ${vw('padding-left', 16, 40)}
  ${vw('padding-bottom', 32, 40)}
`;

const PlanMeta = styled.div`
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
  }
`;

const PlanImage = styled.img`
  ${vw('width', 256, 640, 734)}
  height: auto;
`;

const ThumbCompass = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${vw('margin-top', 16, 0)}
  ${vw('margin-bottom', 32, 0)}

  @media ${media.tablet} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PlateThumb = styled.img`
  ${vw('margin-right', 8)}
  ${vw('width', 200, 283, 320)}
  height: auto;
`;

const Compass = styled.img`
  ${vw('width', 14, 24)}
  ${vw('height', 14, 24)}
  ${vw('margin-top', 0, 20)}
`;

const AreaUnit = styled.span`
  @media ${media.tablet} {
    display: none;
  }
`;

export default Accordion;

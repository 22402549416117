import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { hot } from 'react-hot-loader';
import { loadReCaptcha } from 'react-recaptcha-v3';

import { prerenderStyles } from 'src/plugins/prerender';
import routes from 'src/routes';
import appConfig from 'src/config/app.conf';

import LoadingScreen from 'components/LoadingScreen';
import Header from 'components/Header';
import Footer from 'components/Footer/Footer';
import Intl from 'components/Intl';
import BackgroundImage from 'src/assets/images/footer/wood_texture.jpg';
import Map from 'src/assets/images/footer/map.png';
import TreeRingsA from 'src/assets/images/elements/tree_rings_1.svg';
import GhostButton from 'src/components/GhostButton';
// import GhostButtonA from 'src/components/GhostButtonA';

import vw from 'src/styles/utils';
import theme from 'src/styles/theme';

class App extends Component {
  componentDidMount() {
    prerenderStyles();
    !window.__PRERENDERING && loadReCaptcha(appConfig.sitekey);
  }

  generateRoutes() {
    return routes.map((route, index) => {
      const { path, component } = route;
      const Component = require(`src/${component}`).default;
      return <Route exact path={path} component={Component} key={index} />;
    });
  }

  render() {
    return (
      <Route
        render={({ location }) => (
          <Intl>
            {(t, { getLocaleURL }) => (
              <Root>
                <LoadingScreen />
                <Header />
                <TransitionGroup>
                  <CSSTransition
                    key={location.key}
                    timeout={500}
                    classNames="fade"
                  >
                    <Switch location={location}>{this.generateRoutes()}</Switch>
                  </CSSTransition>
                </TransitionGroup>
                <TreeRingsWatermark src={TreeRingsA} />
                <Footer
                  color={theme.color.cc_grey}
                  bgImage={BackgroundImage}
                  logo={require('src/assets/images/logo/cc_logo_full_white.svg')}
                  project="Cedar Creek"
                  column1={
                    <>
                      <h3>{t.footer.column1.title}</h3>
                      <a className="footer-link" href="tel:6045298868">
                        604.529.8868
                      </a>
                      <a
                        className="footer-link"
                        href="mailto:admin@liveatcedarcreek.com"
                      >
                        admin@liveatcedarcreek.com
                      </a>
                      <GhostButton
                        to={`${getLocaleURL()}/register`}
                        text={t.footer.column1.buttonLabel}
                      />
                    </>
                  }
                  column2={
                    <>
                      <h3>{t.footer.column2.title}</h3>
                      <p>{t.footer.column2.text}</p>
                      {/* <GhostButtonA
                        href="https://app.acuityscheduling.com/schedule.php?owner=20816987&calendarID=4531493"
                        text={t.footer.column2.buttonLabel}
                        target="_blank"
                      /> */}
                    </>
                  }
                  column3={
                    <>
                      <h3>{t.footer.column3.title}</h3>
                      <FooterMap src={Map} />
                    </>
                  }
                  disclaimer={t.footer.disclaimer}
                />
              </Root>
            )}
          </Intl>
        )}
      />
    );
  }
}

const Root = styled.div`
  position: relative;
  .fade-enter {
    opacity: 0;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 0.5s;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: all 0.5s;
  }
`;

const FooterMap = styled.img`
  ${vw('width', 158, 200)};
  height: auto;
`;

const TreeRingsWatermark = styled.img`
  position: absolute;
  z-index: 0;
  ${vw('width', 200, 350, 500)}
  ${vw('height', 215, 376, 539)}
  ${vw('top', -65, -140, -183)}
  ${vw('left', -75, -120, -145)}

  mix-blend-mode: normal;
  opacity: 0.05;
  transform: matrix(0, -1, -1, 0, 0, 0);
`;

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;

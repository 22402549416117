import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import GhostButton from 'src/components/GhostButton';
import PhotoPortrait from 'src/components/PhotoPortrait';
import TranquilRendering from 'src/assets/images/home/tranquil-rendering.png';

import vw from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

function TranquilBanner() {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          <PhotoPortrait src={TranquilRendering} />
          <Article>
            <h1>{t.home.banner2.title}</h1>
            <p className="body">{t.home.banner2.description}</p>
            <GhostButton
              to={`${getLocaleURL()}/interiors`}
              text={t.home.banner2.buttonLabel}
            />
          </Article>
        </Root>
      )}
    </Intl>
  );
}

const Root = styled.div`
  background-color: ${theme.color.cc_off_white_bg};
  ${vw('padding-top', 32, 60, 130)}
  ${vw('padding-bottom', 58, 105, 130)}
  ${vw('padding-left', 32, 61, 207)}
  ${vw('padding-right', 32, 173, 80)}
  z-index: 0;
  position: relative;

  @media ${media.desktop} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const Article = styled.div`
  ${vw('width', 256, 530, 460)}
  ${vw('margin-top', 16, 30, 112)}

  p {
    ${vw('margin-bottom', 16, 30, 40)}
  }
`;

export default TranquilBanner;

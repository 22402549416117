import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';
import theme from 'src/styles/theme';

const Input = (props) => {
  const { label, name, id, required, type } = props;
  return (
    <Root>
      <label htmlFor={id} className="form label">
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={id}
        required={required}
        className="form input"
      />
    </Root>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
};

Input.defaultProps = {
  type: 'text',
};

const Root = styled.div`
  width: 100%;
  ${vw('margin-bottom', 20, 35)}
  label {
    display: block;
    ${vw('margin-bottom', 4)}
  }
  input {
    appearance: none;
    width: 100%;
    border-radius: 0;
    border: 2px solid transparent;
    background-color: white;
    transition: 0.2s all ease;
    ${vw('height', 36, 40)}
    ${vw('padding-top', 9, 10)}
    ${vw('padding-right', 12)}
    ${vw('padding-left', 12)}
    ${vw('padding-bottom', 9, 10)}
    &:focus,
    &:hover {
      outline: none;
      background-color: white;
      border: 2px solid ${theme.color.cc_blue};
    }

    &:-webkit-autofill  {
      appearance: none !important; 
      box-shadow: transparent 0px 0px 0px 1000px inset !important;
      transition: background-color 5000s ease-in-out 0s !important;
    }

    &:autofill {
      appearance: none;
      box-shadow: transparent 0px 0px 0px 1000px inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
`;

export default Input;

export default [
  {
    image: require('src/assets/images/developer/projects/cameron-burnaby.jpg'),
    text: 'Cameron, Burnaby',
    small: true,
  },
  {
    image: require('src/assets/images/developer/projects/charland-coquitlam.jpg'),
    text: 'Charland, Coquitlam',
    small: true,
  },
  {
    image: require('src/assets/images/developer/projects/dominion-new_westminster.jpg'),
    text: 'Dominion, New Westminster',
    small: true,
  },
  {
    image: require('src/assets/images/developer/projects/radius-vancouver.jpg'),
    text: 'Radius, Vancouver',
  },
  {
    image: require('src/assets/images/developer/projects/saffron_on_the_park-richmond.jpg'),
    text: 'Saffron on the Park, Richmond',
  },
  {
    image: require('src/assets/images/developer/projects/seasons-burnaby.jpg'),
    text: 'Seasons, Burnaby',
  },
  {
    image: require('src/assets/images/developer/projects/storybrook-burnaby.jpg'),
    text: 'Storybrook, Burnaby',
  },
];

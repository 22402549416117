import React from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';

import PageHeroBanner from 'src/components/PageHeroBanner';
import HeartBanner from './HeartBanner';
import PrivateBanner from './PrivateBanner';

import HeroImage from 'src/assets/images/interiors/hero.jpg';

import vw from 'src/styles/utils';

const Interiors = () => {
  return (
    <Intl>
      {(t) => {
        return (
          <Root>
            <PageHeroBanner
              title={t.interiors.hero.title}
              body={t.interiors.hero.body}
              image={HeroImage}
            />
            <HeartBanner />
            <PrivateBanner />
          </Root>
        );
      }}
    </Intl>
  );
};

export default Interiors;

const Root = styled.main`
  ${vw('padding-top', 52, 80, 120)}
  background-color: #F8F8F6;
`;

import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import FullLogo from 'src/assets/images/logo/cc_logo_full@2x.png';

function Logo() {
  return (
    <Root src={FullLogo}>
    </Root>
  );
};

export default Logo;

const Root = styled.img`
  ${vw('width', 80, 121, 159)}
  ${vw('height', 56, 84, 111)}
  transition: 0.2s all ease;
`;

import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import vw from './utils';
import media from './media';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
    background-color: #FFFFFF;
    width: 100%;
    overflow-x: hidden;
  }

  body {
    font-family: ${theme.font.family};
  }

  a,
  button {
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
  }

  main {
    width: 100%;
    height: 100%;
  }

  h1 {
    font-family: ${theme.font.family};
    font-weight: normal;
    color: ${theme.color.cc_grey};
    ${vw('font-size', 24, 36, 36)};
    ${vw('letter-spacing', 2)};
    ${vw('line-height', 32, 43)}
    text-transform: uppercase;
    font-weight: 700;

    &.localized {
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.16;
      text-align: center;
      @media ${media.tablet} {
        line-height: normal;
      }
    }
    &.privacy-policy {
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      color: ${theme.color.darkGrey};
      ${vw('font-size', 32)}
      ${vw('line-height', 40)}
      ${vw('letter-spacing', 0.8)}
    }
  }

  h2 {
    font-family: ${theme.font.family};
    color: ${theme.color.cc_grey};
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    ${vw('font-size', 22, 28)}
    ${vw('line-height', 28, 33)}
    ${vw('letter-spacing', 2)}
  }

  h3 {
    font-family: ${theme.font.family};
    font-weight: 300;
    text-transform: uppercase;
    ${vw('margin-bottom', 16, 30)}
    ${vw('font-size', 18)}
    ${vw('line-height', 24)}
    ${vw('letter-spacing', 5, 7)}

    &.localized {
      color: ${theme.color.blue};
      font-weight: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      line-height: 1.25;
      ${vw('font-size', 32, 60, 60)}
      @media ${media.tablet} {
        line-height: normal;
      }
    }
  }

  h4 {
    font-family: ${theme.font.family};
    ${vw('font-size', 18)}
    ${vw('line-height', 24)}
    ${vw('letter-spacing', 7)}
    text-align: center;
    text-transform: uppercase;
    color: #4B4E52;
  }

  p {
    font-family: ${theme.font.family};
    &.body {
      color: ${theme.color.cc_grey};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      text-transform: uppercase;
      ${vw('font-size', 13, 16)}
      ${vw('line-height', 26, 32)}
      ${vw('letter-spacing', 1, 3)}
      ${vw('margin-top', 16, 20)}
      ${vw('margin-bottom', 16, 20)}
    }
    &.body-italic {
      font-family: ${theme.font.family};
      color: ${theme.color.cc_grey};
      font-weight: normal;
      font-stretch: normal;
      font-style: italic;
      letter-spacing: 0;
      ${vw('font-size', 13, 18)}
      ${vw('line-height', 22)}
    }
    &.body-sans-serif {
      font-family: ${theme.font.sans_serif_family};
      color: ${theme.color.cc_grey};
      font-weight: 400;
      font-stretch: normal;
      font-style: normal;
      ${vw('font-size', 13, 16)}
      ${vw('line-height', 25)}
    }
    &.ledmac-footer {
      font-family: ${theme.font.family};
      color: ${theme.color.cc_grey};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      ${vw('font-size', 12, 13)}
      ${vw('line-height', 18, 20)}
      ${vw('letter-spacing', 0.22, 0.24)}
    }
    &.caption {
      font-family: ${theme.font.sans_serif_family};
      color: ${theme.color.cc_grey};
      text-transform: uppercase;
      ${vw('font-size', 11, 14)};
      ${vw('line-height', 13, 16)};
      ${vw('letter-spacing', 1, 2)};
      ${vw('margin-top', 4, 5)};
    }
  }

  .disclaimer {
    font-family: ${theme.font.sans_serif_family};
    color: ${theme.color.cc_grey};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    text-transform: uppercase;
    ${vw('font-size', 13)}
    ${vw('line-height', 17)}
    ${vw('letter-spacing', 1)}
  }

  .ledmac-footer {
    &.subheader {
      font-family: ${theme.font.family};
      color: ${theme.color.darkGrey};
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      ${vw('font-size', 15)}
      ${vw('line-height', 22)}
      ${vw('letter-spacing', 0.25)}
    }
    &.link {
      font-family: ${theme.font.family};
      color: ${theme.color.cc_grey};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      text-align: center;
      text-transform: uppercase;
      ${vw('font-size', 12)}
      ${vw('line-height', 14, 16)}
      ${vw('letter-spacing', 2)}

      @media ${media.desktop} {
        text-decoration: none;
      }
    }
    &.disclaimer {
      font-family: ${theme.font.family};
      color: ${theme.color.darkGrey};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      text-align: center;
      ${vw('font-size', 10)}
      ${vw('line-height', 16)}
      ${vw('letter-spacing', 0.1)}
    }
  }

  .form {
    font-family: ${theme.font.family};
    &.input {
      color: ${theme.color.darkGrey};
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      transition: ${theme.transition};
      ${vw('font-size', 14, 15)}
      ${vw('line-height', 18, 20)}
      ${vw('letter-spacing', 0.26, 0.28)}
      &.inactive {
        color: rgba(75, 78, 82, 0.3);
        text-transform: uppercase;
        font-weight: 500;
      }
    }
    &.label {
      color: ${theme.color.cc_grey};
      font-weight: 500;
      font-stretch: normal;
      font-style: italic;
      ${vw('font-size', 13, 18)}
      ${vw('line-height', 16, 22)}
    }
  }

  input:-webkit-autofill {
    border: 3px solid blue;
  }
  input:autofill {
    border: 3px solid blue;
  }

  .label {
    color: ${theme.color.cc_grey};
    font-weight: 500;
    font-stretch: normal;
    font-style: italic;
    ${vw('font-size', 13, 18)}
    ${vw('line-height', 16, 22)}
  }

  .button {
    font-weight: 600;
    font-family: ${({ theme }) => theme.font.sans_serif_family};
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    text-transform: uppercase;

    ${vw('font-size', 13)}
    ${vw('letter-spacing', 2, 4)}
    ${vw('padding-top', 6, 10)}
    ${vw('padding-bottom', 6, 10)}
    &.blue {
      color: ${theme.color.blue};
    }
    &.white {
      color: white;
    }
  }

  .button-ghost {
    color: ${({ theme }) => theme.color.cc_grey};
    background-color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    filter: drop-shadow(0px 3px 9px rgba(75, 78, 82, 0.3));
    display: inline-block;
    margin: auto 0;
    border: 1px solid ${({ theme }) => theme.color.cc_grey};
    ${vw('font-size', 13)}
    ${vw('line-height', 20)}
    ${vw('letter-spacing', 3.5, 4)}
    ${vw('padding-left', 16, 40)}
    ${vw('padding-right', 16, 40)}
    ${vw('padding-top', 6, 10)}
    ${vw('padding-bottom', 6, 10)}


    @media ${media.desktop} {
      transition: ${({ theme }) => theme.transition};
      &:hover {
        background-color: ${({ theme }) => theme.color.cc_blue};;
        color: #fff;
        border: 1px solid transparent;
      }
    }
  }

  .container {
    ${vw('padding-left', 16, 0)}
    ${vw('padding-right', 16, 0)}
    ${vw('padding-top', 58, 120)}
    ${vw('padding-bottom', 128, 120)}
  }

  .hide-on-mobile {
    display: none;

    @media ${media.tablet} {
      display: unset;
    }
  }

  .show-on-mobile {
    display: unset;

    @media ${media.tablet} {
      display: none;
    }
  }

  iframe {
    display: none;
  }
`;

import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';
import Tagline from 'components/Tagline';
import HeroImage from 'src/assets/images/home/hero.jpg';
import vw from 'src/styles/utils';
import theme from 'src/styles/theme';
import media from 'src/styles/media';

function Hero() {
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root className="with-watermark">
          <Card>
            <CardBanner>
              <Tagline color="#fff" />
              <BannerTitle>{t.home.nowSelling}</BannerTitle>
            </CardBanner>
          </Card>
        </Root>
      )}
    </Intl>
  );
}

const Root = styled.div`
  z-index: 2;
  position: relative;
  ${vw('margin-top', 32, 80, 0)}
`;

const Card = styled.div`
  ${vw('width', 288, 708, 1150)}
  margin-left: auto;
  margin-right: 0;
  background-image: url(${HeroImage});
  background-size: cover;
  position: relative;
  ${vw('height', 433, 554, 1000)}

  @media ${media.desktop} {
    height: 100vh;
  }
`;

const CardBanner = styled.div`
  width: 100%;
  ${vw('height', 117, 84)}
  ${vw('padding-left', 20)}
  ${vw('padding-top', 17)}
  ${vw('padding-bottom', 7)}
  background: linear-gradient(270deg, rgba(0, 174, 199, 0.3) 1.06%, #00AEC7 76.16%);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media ${media.tablet} {
    flex-direction: unset;
    align-items: center;
    justify-content: unset;
  }
`;

const BannerTitle = styled.h1`
  font-family: ${theme.font.family};
  ${vw('font-size', 15, 19)}
  ${vw('line-height', 15, 32)}
  ${vw('margin-left', 0, 20)}
  ${vw('letter-spacing', 3, 4)}

  text-transform: uppercase;
  color: ${theme.color.cc_off_white};
`;

export default Hero;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Intl from 'src/components/Intl';
import LanguageSwitcher from './LanguageSwitcher';
import PrimaryButton from './PrimaryButton';
import FullLogo from './FullLogo';
import CompactLogo from './CompactLogo';
import useScrollDown from 'components/hooks/useScrollDown';
import MenuToggle from 'src/components/icons/MenuToggle';
import TreeRingsA from 'components/TreeRingsA';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

function Header() {
  const [opened, setOpened] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);

  const hasPageLoaded = useSelector((state) => state.load.hasLoaded);
  const isScrollingDown = useScrollDown(window.innerHeight * 1.3 || 900);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (hasPageLoaded) {
      setTimeout(() => setHasLoaded(true), 2000);
    }
  }, [hasPageLoaded]);

  const handleScroll = (e) => {
    // checks to see if user has scrolled past 50px
    if (e.target.scrollingElement.scrollTop > 50) {
      setHasScrolled(true);
    } else {
      setHasScrolled(false);
    }
  };

  const toggleOpen = () => setOpened(!opened);

  const closeMenu = () => setOpened(false);

  // Need to refactor
  const isHomePageOrFloorplansPage = () => {
    var check = false;
    if (
      document.location.pathname === '/' ||
      document.location.pathname === '/tc' ||
      document.location.pathname === '/ko' ||
      document.location.pathname === '/floorplans' ||
      document.location.pathname === '/floorplans/' ||
      document.location.pathname === '/tc/floorplans' ||
      document.location.pathname === '/ko/floorplans'
    ) {
      check = true;
    }
    return check;
  };

  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root
          sticky={hasScrolled || !isHomePageOrFloorplansPage()}
          opened={opened}
          style={{
            transform:
              isScrollingDown || !hasLoaded
                ? 'translateY(-130%)'
                : 'translateY(0)',
            opacity: hasLoaded ? 1 : 0,
          }}
        >
          <LogoLink onClick={closeMenu} to={`${getLocaleURL()}`}>
            {hasScrolled || !isHomePageOrFloorplansPage() ? (
              <CompactLogo />
            ) : (
              <FullLogo />
            )}
          </LogoLink>

          {/* Tablet & Mobile Menu */}
          <MenuToggle
            onClick={toggleOpen}
            className="menu-toggle"
            menuOpen={opened}
            style={{
              opacity: hasLoaded ? 1 : 0,
            }}
          />
          {/* <RegisterLink
            className="regsiter-link"
            to={`${getLocaleURL()}/register`}
            style={{
              opacity: hasLoaded ? 1 : 0,
            }}
          >
            {t.pages.register}
          </RegisterLink> */}

          {/* Register button */}
          {/* <RegisterButton
            onClick={closeMenu}
            className="button show-on-tablet"
            to={`${getLocaleURL()}/register`}
            text={t.pages.register}
          />  */}
          {/* Book appt button */}
          <RegisterButton
            onClick={closeMenu}
            className="button show-on-tablet"
            // href="https://app.acuityscheduling.com/schedule.php?owner=20816987&calendarID=4531493"
            scrollLink
            to="footer"
            text={t.contact}
          />

          <MenuWrapper
            opened={opened}
            style={{
              opacity: hasLoaded ? 1 : 0,
            }}
          >
            <LanguageSwitcher className="show-on-desktop" />
            <nav>
              <StyledLink
                onClick={toggleOpen}
                to={`${getLocaleURL()}/floorplans`}
              >
                {t.pages.floorplans}
              </StyledLink>
              <StyledLink
                onClick={toggleOpen}
                to={`${getLocaleURL()}/interiors`}
              >
                {t.pages.interiors}
              </StyledLink>
              <StyledLink
                onClick={toggleOpen}
                to={`${getLocaleURL()}/features`}
              >
                {t.pages.features}
              </StyledLink>
              <StyledLink
                onClick={toggleOpen}
                to={`${getLocaleURL()}/neighbourhood`}
              >
                {t.pages.neighbourhood}
              </StyledLink>
              <StyledLink onClick={toggleOpen} to={`${getLocaleURL()}/gallery`}>
                {t.pages.gallery}
              </StyledLink>
              <StyledLink
                onClick={toggleOpen}
                to={`${getLocaleURL()}/developer`}
              >
                {t.pages.developer}
              </StyledLink>
              {/* <RegisterButton
                onClick={closeMenu}
                className="button hide-on-tablet"
                to={`${getLocaleURL()}/register`}
                text={t.pages.register}
              /> */}
              <RegisterButton
                onClick={closeMenu}
                className="button"
                // href="https://app.acuityscheduling.com/schedule.php?owner=20816987&calendarID=4531493"
                text={t.contact}
                scrollLink
                to="footer"
              />
            </nav>
            <LanguageSwitcher onClick={toggleOpen} className="show-on-tablet" />
            <TreeRingsA className="tree-rings" width="708" height="767" />
          </MenuWrapper>
        </Root>
      )}
    </Intl>
  );
}

export default Header;

const Root = styled.header`
  position: ${(props) => (props.sticky ? 'fixed' : 'relative')};
  background-color: ${(props) => (props.sticky ? '#ffffff' : 'transparent')};
  display: flex;
  align-items: center;
  top: 0;

  ${(props) =>
    props.opened &&
    `
      display: unset;
      position: fixed;
      height: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #F8F8F6;
      align-items: unset;

      @media ${media.desktop} {
        display: flex;
        align-items: flex-start;
      }
    `}

  z-index: 90;
  width: 100%;
  ${vw('padding-left', 16, 24, 48)}
  ${vw('padding-right', 16, 24, 48)}
  ${vw('padding-top', 10, 24, 20)}
  ${vw('padding-bottom', 10, 24)}
  transition: 0.2s all ease;
  box-shadow: ${(props) =>
    props.sticky ? '0px 2px 14px rgba(75, 78, 82, 0.100705);' : 'none'};

  @media ${media.desktop} {
    position: ${(props) => (props.sticky ? 'fixed' : 'relative')};
    background-color: ${(props) => (props.sticky ? '#ffffff' : 'transparent')};
    display: flex;
    bottom: unset;
    height: ${vwDesktop(120)};
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
  }

  nav {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${media.desktop} {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: ${vwDesktop(20)};
    }
  }

  .menu-toggle {
    @media ${media.desktop} {
      display: none;
    }
  }
`;

const LogoLink = styled(Link)`
  ${vw('width', 80, 125, 161)}
  display: inline-flex;
  align-items: center;

  @media ${media.desktop} {
    grid-column: 0 / 1;
    grid-row: 1 / 3;
    transform: translateY(20px);
  }
`;

const MenuWrapper = styled.div`
  transition: 0.2s all ease;
  position: relative;
  ${vw('margin-top', 62, 194, 0)}

  ${(props) => !props.opened && `display: none;`}

  @media ${media.desktop} {
    display: flex;
    flex-direction: column;
    align-items: end;
    .tree-rings {
      display: none;
    }
  }

  .tree-rings {
    position: fixed;
    ${vw('width', 296, 708)}
    ${vw('height', 320, 767)}
    bottom: 0;
    mix-blend-mode: normal;
    opacity: 0.1;
    transform: matrix(0, 1, 1, 0, 0, 0) translateX(70%);
    z-index: -1;
  }
`;

const StyledLink = styled(NavLink)`
  font-family: ${({ theme }) => theme.font.sans_serif_family};
  text-transform: uppercase;
  font-weight: 600;
  ${vw('font-size', 13)}
  ${vw('line-height', 20)}
  ${vw('letter-spacing', 4)}
  ${vw('margin-bottom', 24, 40, 0)}
  color: ${({ theme }) => theme.color.cc_grey};

  @media ${media.desktop} {
    ${vw('margin-right', 30)}
  }

  &:hover,
  &.active {
    color: ${theme.color.cc_blue_dark};
  }
`;

const RegisterButton = styled(PrimaryButton)`
  &.hide-on-tablet {
    display: none;

    @media ${media.desktop} {
      display: unset;
      grid-column: 3 / 4;
      grid-row: 2 / 3;
      margin-left: ${vwDesktop(5)};
    }
  }

  &.show-on-tablet {
    display: none;
    position: absolute;
    ${vw('top', 29)}
    ${vw('right', 24)}

    @media ${media.tablet} {
      display: unset;
    }

    @media ${media.desktop} {
      display: none;
    }
  }
`;

// const RegisterLink = styled(StyledLink)`
//   transition: 0.2s all ease;
//   position: absolute;
//   ${vw('top', 16, 32)}
//   ${vw('right', 16)}
//   display: unset;
//   @media ${media.tablet} {
//     display: none;
//   }
// `;

import React from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';

import PageHeroBanner from 'src/components/PageHeroBanner';
import NeighbourhoodMap from './NeighbourhoodMap';
import BannerLandscapeRight from 'src/components/BannerLandscapeRight';
import BannerPortraitLeft from 'src/components/BannerPortraitLeft';

import HeroImage from 'src/assets/images/neighbourhood/hero.jpg';
import BannerPhoto from 'src/assets/images/neighbourhood/photo.jpg';

import vw from 'src/styles/utils';

const Neighbourhood = () => {
  return (
  <Intl>
  {(t, { getLocaleURL, locale }) => (
    <Root>
      <PageHeroBanner
        title={t.neighbourhood.hero.title}
        body={t.neighbourhood.hero.body}
        image={HeroImage}
      />
      <NeighbourhoodMap />
      <BannerLandscapeRight
        title={t.neighbourhood.banner1.title}
        videoSrc="https://player.vimeo.com/external/662714758.sd.mp4?s=90df7d63b712233e2b8a87d120fbf6ff5f63f168&profile_id=165"
      />
      <BannerPortraitLeft
        body={t.neighbourhood.banner2.body}
        image={BannerPhoto}
      />
    </Root>
    )}
  </Intl>
  );
};

const Root = styled.main`
  ${vw('padding-top', 52, 80, 120)}
  background-color: #F8F8F6;
`;

export default Neighbourhood;

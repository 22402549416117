export default [
  {
    title: 'Parks & Recreation',
    titleId: 'parks',
    points: [
      {
        text: 'Ernie Winch Park',
        top: '56.9%',
        left: '64.8%',
      },
      {
        text: 'Byrne Creek Ravine Park',
        top: '62.3%',
        left: '36.8%',
      },
      {
        text: 'Edmonds Park',
        top: '37.1%',
        left: '54.7%',
      },
      {
        text: 'Edmonds Community Centre',
        top: '38.9%',
        left: '58.7%',
      },
      {
        text: 'Canadian Motion Picture Park',
        top: '94.3%',
        left: '35.4%',
      },
      {
        text: 'Riverway Golf Course',
        top: '82.3%',
        left: '12.3%',
      },
      {
        text: 'The Museum of the Royal Westminster',
        top: '76.9%',
        left: '96.5%',
        bottomCallout: true,
      },
    ],
  },
  {
    title: 'Restaurants & Cafes',
    titleId: 'restaurants',
    points: [
      {
        text: 'Trattoria',
        top: '23.8%',
        left: '1.2%',
      },
      {
        text: 'Cactus Club Café',
        top: '82.4%',
        left: '22.1%',
      },
      {
        text: 'Body Energy Club',
        top: '42.5%',
        left: '51.1%',
      },
      {
        text: 'The Boat House',
        top: '87.3%',
        left: '93.7%',
      },
      {
        text: 'La Foret café',
        top: '40.7%',
        left: '8.8%',
      },
      {
        text: 'Starbucks',
        top: '44.2%',
        left: '47.7%',
      },
      {
        text: 'Tealips Bubble Tea & Coffee Café',
        top: '39.7%',
        left: '48.2%',
      },
      {
        text: 'Nao Sushi',
        top: '48.5%',
        left: '46.3%',
      },
      {
        text: 'Mr. Ho Wonton House',
        top: '42.5%',
        left: '39.4%',
      },
    ],
  },
  {
    title: 'Education',
    titleId: 'education',
    points: [
      {
        text: 'St. Thomas Moore Collegiate',
        top: '26.4%',
        left: '66.3%',
        arrow: true,
        arrowTop: 'calc(100% + 5px)',
        arrowLeft: '20%',
        transform: 'rotate(190deg)',
      },
      {
        text: 'Ecole Cariboo Hill Secondary',
        top: '17.2%',
        left: '92.3%',
      },
      {
        text: 'Byrne Creek Secondary',
        top: '70.2%',
        left: '55.4%',
      },
      {
        text: 'Twelfth Ave Elementary',
        top: '52.1%',
        left: '72%',
      },
      {
        text: 'Taylor Park Elementary',
        top: '70.2%',
        left: '46.3%',
      },
      {
        text: 'Stride Ave Elementary',
        top: '63.7%',
        left: '53.2%',
      },
      {
        text: 'Angel Wings Daycare Centre',
        top: '43.4%',
        left: '59.9%',
      },
      {
        text: 'Little Montessori Day Care',
        top: '34%',
        left: '35%',
      },
      {
        text: 'St. Matthews Day Care Centre',
        top: '27.1%',
        left: '70.2%',
        arrow: true,
        arrowTop: 'calc(100% + 3px)',
        arrowLeft: '10%',
        transform: 'rotate(200deg)',
      },
    ],
  },
  {
    title: 'Shopping',
    titleId: 'shopping',
    points: [
      {
        text: 'Highgate Village Shopping Centre',
        top: '43.4%',
        left: '44.2%',
      },
      {
        text: 'Big Bend Crossing',
        top: '82.5%',
        left: '27.9%',
      },
      {
        text: 'Metrotown',
        top: '31.5%',
        left: '6.6%',
      },
      {
        text: 'RONA',
        top: '47%',
        left: '53.4%',
      },
      {
        text: 'Costco',
        top: '3.1%',
        left: '86.3%',
        bottomCallout: true,
        arrow: true,
        arrowTop: '-25px',
        arrowLeft: '75%',
        transform: 'rotate(25deg)',
      },
      {
        text: 'Save-On Foods',
        top: '43.4%',
        left: '48.3%',
      },
      {
        text: 'Kims Mart',
        top: '63.2%',
        left: '44.2%',
      },
      {
        text: 'BC Liquor Store',
        top: '42.2%',
        left: '52%',
      },
      {
        text: 'Tommys Market ltd',
        top: '42.7%',
        left: '55.5%',
      },
    ],
  },
  {
    title: 'Fitness',
    titleId: 'fitness',
    points: [
      {
        text: 'Omac Taekwondo',
        top: '61.2%',
        left: '71.1%',
      },
      {
        text: 'Dancin’ Stars Performing Arts',
        top: '26.9%',
        left: '65.7%',
        arrow: true,
        arrowTop: '99%',
        arrowLeft: '85%',
        transform: 'rotate(140deg)',
      },
      {
        text: 'Fitness World',
        top: '35.9%',
        left: '20.6%',
      },
      {
        text: 'She’s Fit! Metrotown',
        top: '17.7%',
        left: '4.9%',
        arrow: true,
        arrowTop: '-10%',
        arrowLeft: '-20px',
        transform: 'rotate(290deg)',
      },
      {
        text: 'Anytime Fitness',
        top: '57.6%',
        left: '84.2%',
      },
      {
        text: 'Club 16 Trevor Linden Fitness',
        top: '42.8%',
        left: '50.2%',
      },
    ],
  },
];

import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Intl from 'components/Intl';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

const social = [
  {
    icon: require('src/assets/images/icons/icon-weixin.png'),
    wechat: true,
    text: 'Weixin',
  },
  {
    icon: require('src/assets/images/icons/icon-facebook.png'),
    to: 'https://www.facebook.com/Ledmachomes/',
    text: 'Facebook',
  },
  {
    icon: require('src/assets/images/icons/icon-twitter.png'),
    to: 'https://twitter.com/LedMacHomes',
    text: 'Twitter',
  },
  {
    icon: require('src/assets/images/icons/icon-youtube.png'),
    to: 'https://www.youtube.com/channel/UCwdwI3hjms5uhcsERXL2bIg',
    text: 'Youtube',
  },
  {
    icon: require('src/assets/images/icons/icon-instagram.png'),
    to: 'https://www.instagram.com/ledmachomes/',
    text: 'Instagram',
  },
  {
    icon: require('src/assets/images/icons/icon-linkedin.png'),
    to: 'https://www.linkedin.com/company/ledingham-mcallister/ ',
    text: 'LinkedIn',
  },
];

const Corporate = (props) => {
  const { disclaimer } = props;

  const [isWechatOpen, setIsWechatOpen] = useState(false);

  const today = new Date();
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root>
          <Wrapper>
            <div>
              <h3 className="ledmac-footer subheader">
                {t.footer.corporate.title}
              </h3>
              <p className="ledmac-footer">{t.footer.corporate.text}</p>
              <Social>
                {social.map((item, index) =>
                  item.wechat ? (
                    <div onClick={() => setIsWechatOpen(true)} key={index}>
                      <img src={item.icon} alt={item.text} />
                    </div>
                  ) : (
                    <a
                      href={item.to}
                      key={index}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={item.icon} alt={item.text} />
                    </a>
                  )
                )}
              </Social>
            </div>
            <a
              href="https://ledmac.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="logo"
            >
              <Logo
                src={require('src/assets/images/logo/ledmac-logo.svg')}
                alt="Ledingham McAllister"
              />
            </a>
          </Wrapper>
          <Container>
            <p className="ledmac-footer disclaimer">{disclaimer}</p>
            <Links>
              <p className="ledmac-footer link">
                © {today.getFullYear()} Ledingham McAllister.
                <br /> all rights reserved
              </p>
              <span />
              <Link
                className="ledmac-footer link"
                to={`${getLocaleURL()}/privacy`}
              >
                {t.footer.corporate.privacy}
              </Link>
              <span />
              <a
                className="ledmac-footer link"
                href="https://www.bamdigital.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                webSite by Bam Digital
              </a>
            </Links>
            <WeChat
              style={{
                opacity: isWechatOpen ? 1 : 0,
                pointerEvents: isWechatOpen ? 'all' : 'none',
              }}
            >
              <div>
                <img
                  src={require('src/assets/images/icons/Ledmac_Wechat_Qr_Code_Banner.jpeg')}
                  alt=""
                  className="banner"
                />
                <img
                  src={require('src/assets/images/icons/wechat-close-icon.png')}
                  alt=""
                  className="close"
                  onClick={() => setIsWechatOpen(false)}
                />
              </div>
            </WeChat>
          </Container>
        </Root>
      )}
    </Intl>
  );
};

Corporate.propTypes = {
  disclaimer: PropTypes.string,
};

const Root = styled.div`
  background-color: ${theme.color.cc_off_white};
  width: 100%;
  padding-top: ${vwMobile(40)};
  .disclaimer {
    margin: 0 0 ${vwMobile(20)};
    font-family: ${theme.font.sans_serif_family};
    text-transform: none;
    opacity: 0.7;
    font-size: 10px;
    letter-spacing: 0;
  }
  @media ${media.tablet} {
    padding-top: ${vwTablet(60)};
    .disclaimer {
      margin: 0 0 ${vwTablet(20)};
    }
  }
  @media ${media.desktop} {
    padding-top: ${vwDesktop(40)};
    .disclaimer {
      margin: 0 0 ${vwDesktop(16)};
    }
  }
`;

const Container = styled.div`
  background-color: white;
  padding: 16px ${vwMobile(16)};
  @media ${media.tablet} {
    padding: 30px ${vwTablet(60)};
  }
  @media ${media.desktop} {
    padding: 18px ${vwDesktop(60)};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: ${vwMobile(16)};
  > div {
    order: 2;
  }
  .logo {
    order: 1;

    img {
      ${vw('width', 193, 258)}
      ${vw('height', 90, 120)}
    }
  }

  .subheader {
    font-family: ${theme.font.sans_serif_family};
    color: ${theme.color.cc_grey}
  }

  p {
    margin: ${vwMobile(6)} 0 ${vwMobile(22)};
    font-family: ${theme.font.sans_serif_family};
  }
  @media ${media.tablet} {
    padding: 0 ${vwTablet(60)};
    p {
      margin: ${vwTablet(4)} 0 ${vwTablet(24)};
    }
  }
  @media ${media.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    padding: 0 ${vwDesktop(60)};
    > div {
      order: 1;
    }
    .logo {
      order: 2;
    }
    p {
      max-width: ${vwDesktop(960)};
      margin: ${vwDesktop(4)} 0 ${vwDesktop(24)};
    }
  }
  ${vw('padding-bottom', 32, 60, 30)};

`;

const Logo = styled.img`
  display: block;
  ${vw('width', 170, 170)}
  ${vw('margin-bottom', 40, 60, 0)}
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    color: ${({ theme }) => theme.color.cc_grey};
    text-decoration: underline;
    ${vw('letter-spacing', 2)};
    ${vw('font-size', 12)};
  }
  p,
  a:first-of-type {
    margin-bottom: ${vwMobile(12)};
  }
  span {
    display: none;
  }
  @media ${media.tablet} {
    a {
    }
    p,
    a:first-of-type {
      margin-bottom: ${vwTablet(12)};
    }
  }
  @media ${media.desktop} {
    flex-direction: row;
    justify-content: center;
    br {
      display: none;
    }
    span {
      display: block;
      width: 1px;
      height: ${vwDesktop(12)};
      background-color: ${({ theme }) => theme.color.cc_grey};
      margin: 0 ${vwDesktop(14)};
    }
    p,
    a:first-of-type {
      margin-bottom: 0;
    }
    a {
      color: ${({ theme }) => theme.color.cc_grey};
      transition: ${({ theme }) => theme.transition};
    }
  }
`;

const Social = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${vwMobile(240)};
  margin: 0 auto;
  a,
  div {
    height: ${vwMobile(20)};
    width: ${vwMobile(20)};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
  }
  @media ${media.tablet} {
    width: ${vwTablet(240)};
    a,
    div {
      height: ${vwTablet(20)};
      width: ${vwTablet(20)};
    }
  }
  @media ${media.desktop} {
    margin: 0;
    width: ${vwDesktop(240)};
    a,
    div {
      height: ${vwDesktop(20)};
      width: ${vwDesktop(20)};
      transition: ${({ theme }) => theme.transition};
      cursor: pointer;
    }
  }
`;

const WeChat = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${({ theme }) => theme.transition};
  z-index: 20;
  > div {
    width: ${vwMobile(300)};
    height: ${vwMobile(113)};
    position: relative;
    background-color: white;
    ${vw('width', 300, 500)}
    ${vw('height', 113, 188)}
  }
  .banner {
    height: 100%;
    width: auto;
    max-width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    ${vw('width', 20, 32)}
    ${vw('height', 20, 32)}
  }
`;

export default Corporate;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Button = (props) => {
  const { text, to, href, scrollLink } = props;
  return (
    <Root
      to={to}
      href={href}
      as={href ? 'a' : scrollLink ? ScrollLink : Link}
      target={href && '_blank'}
      rel="noreferrer"
      className="button"
      smooth={true}
      {...props}
    >
      {text}
    </Root>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
  handleClick: PropTypes.func,
  scrollLink: PropTypes.bool,
};

const Root = styled(Link)`
  color: white;
  background-color: ${({ theme }) => theme.color.cc_grey};
  cursor: pointer;
  text-transform: uppercase;
  filter: drop-shadow(0px 3px 9px rgba(75, 78, 82, 0.509774));
  display: flex;
  align-items: center;
  margin: auto 0;
  white-space: nowrap;
  ${vw('padding-left', 12)}
  ${vw('padding-right', 12)}
  /* ${vw('width', 140)} */
  ${vw('height', 36)}

  @media ${media.desktop} {
    transition: ${({ theme }) => theme.transition};
    &:hover {
      background-color: ${({ theme }) => theme.color.cc_blue};
    }
  }
`;

export default Button;

import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

import PhotoFullWidth from 'src/components/PhotoFullWidth';
import SanctuaryRendering from 'src/assets/images/home/interiors-living.jpg';
import TreeRingB from 'src/assets/images/elements/tree_rings_2.svg';

import vw from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

function TranquilBanner() {
  return (
    <Root>
      <PhotoFullWidth src={SanctuaryRendering} />
      <Parallax className="parallax" y={[-20, 20]} tagOuter="figure">
        <Watermark src={TreeRingB} />
      </Parallax>
    </Root>
  );
}

const Root = styled.div`
  position: relative;
  z-index: 2;
  background-color: ${theme.color.cc_off_white};
  ${vw('padding-top', 0)}
  ${vw('padding-bottom', 64, 120)}
  ${vw('padding-left', 16, 24, 40)}
  ${vw('padding-right', 16, 24, 40)}

  .parallax {
    position: absolute;
    ${vw('right', -79, -40, -119)}
    ${vw('bottom', -57, -123, -125)}
  }
`;

// const Article = styled.div`
//   ${vw('width', 256, 534, 900)}
//   ${vw('margin-top', 16, 30, 60)}
//   ${vw('margin-left', 16, 36, 160)}
//   ${vw('margin-right', 16, 150, 60)}

//   @media ${media.desktop} {
//     display: flex;
//     justify-content: space-between;
//     width: unset;
//   }

//   h1 {
//     ${vw('width', 200, 470)}
//   }

//   p {
//     ${vw('margin-top', 16, 30, 0)}
//     ${vw('margin-bottom', 16, 30, 40)}
//   }
// `;

// const ArticleRight = styled.div`
//   ${vw('width', 256, 534, 500)}
// `;

const Watermark = styled.img`
  opacity: 0.2;
  ${vw('width', 180, 240, 370)}
  ${vw('height', 180, 240, 370)}
  @media ${media.desktop} {
    z-index: -1;
  }
`;

export default TranquilBanner;

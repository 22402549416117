import React, { useState } from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import vw, { vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';
import data from 'src/data/landscapePlan';
import theme from 'src/styles/theme';

import LandscapePlanImage from 'src/assets/images/features/landscape_plan.jpg';
import LandscapePlanImageMobile from 'src/assets/images/features/landscape_plan_mobile.jpg';
import MobileArrowLeftIcon from 'src/assets/images/icons/arrow-left.svg';
import MobileArrowRightIcon from 'src/assets/images/icons/arrow-right.svg';

const LandscapePlan = (props) => {
  const [activeOrder, setActiveOrder] = useState(null);
  const [overlayVisible, setOverlayVisible] = useState(true);

  const mouseEnterHandler = (order) => setActiveOrder(order);

  const mouseLeaveHandler = () => setActiveOrder(null);

  const overlayClickHandler = () => setOverlayVisible(false);

  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          {/* Map: Image Banner */}
          {/* Mobile Arrows */}
          <MobileArrowLeft src={MobileArrowLeftIcon} width="24" height="24" />
          <MobileArrowRight src={MobileArrowRightIcon} width="24" height="24" />
          <ImageBanner>
            <ImageDesktop
              src={LandscapePlanImage}
              alt="Landscape Plan"
              width="1000"
            />

            {/* Mobile overlay */}
            {overlayVisible && (
              <MobileOverlay
                onClick={overlayClickHandler}
                onTouchStart={overlayClickHandler}
                onDrag={overlayClickHandler}
                onDragStart={overlayClickHandler}
              >
                <span>DRAG TO EXPLORE</span>
              </MobileOverlay>
            )}

            <ImageMobile src={LandscapePlanImageMobile} width="1000" />
            {data.map((item, index) => (
              <Item
                key={index}
                top={item.top}
                left={item.left}
                active={activeOrder === item.order}
                className={'item' + item.order}
                onMouseEnter={() => mouseEnterHandler(item.order)}
                onMouseLeave={mouseLeaveHandler}
              >
                <ItemLabel>{item.order}</ItemLabel>
              </Item>
            ))}
          </ImageBanner>

          {/* Legends: Text Banner */}
          <TextBanner>
            <TextBannerTitle>{t.features.landscape.title}</TextBannerTitle>

            <TextItemWrapper>
              {[...data].slice(0, 6).map((item, index) => (
                <TextItem
                  key={index}
                  top={item.top}
                  left={item.left}
                  active={activeOrder === item.order}
                  className={'item' + item.order}
                  onMouseEnter={() => mouseEnterHandler(item.order)}
                  onMouseLeave={mouseLeaveHandler}
                >
                  <strong>{item.order}</strong>{' '}
                  {t.features.landscape.labels[item.textId]}
                </TextItem>
              ))}
            </TextItemWrapper>
          </TextBanner>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  position: relative;
  width: 100%;
`;

const ImageBanner = styled.div`
  position: relative;
  width: 100%;
  height: ${vwMobile(367)};
  overflow-x: scroll;
  overflow-y: hidden;

  @media ${media.tablet} {
    height: auto;
  }

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ImageDesktop = styled.img`
  display: none;

  @media ${media.tablet} {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const MobileOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: #4b4e52;
  mix-blend-mode: normal;
  opacity: 0.75;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${theme.font.sans_serif_family};
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 3px;
  text-transform: uppercase;
  color: #f4f4f0;

  @media ${media.tablet} {
    display: none;
  }
`;

const MobileArrowLeft = styled.img`
  position: absolute;
  top: ${vwMobile(172)};
  left: 10px;
  z-index: 2;
  @media ${media.tablet} {
    display: none;
  }
`;

const MobileArrowRight = styled.img`
  position: absolute;
  top: ${vwMobile(172)};
  right: 10px;
  z-index: 2;
  @media ${media.tablet} {
    display: none;
  }
`;

const ImageMobile = styled.img`
  width: auto;
  height: ${vwMobile(367)};

  @media ${media.tablet} {
    display: none;
  }
`;

const TextBanner = styled.div`
  background-color: #f2f2ec;
  ${vw('padding-top', 32, 30, 26)}
  ${vw('padding-left', 16, 60, 50)}
  ${vw('padding-bottom', 32, 55, 66)}
  ${vw('padding-right', 16, 60, 50)}

  @media ${media.desktop} {
    display: flex;
    align-items: flex-start;
  }
`;

const TextBannerTitle = styled.h2`
  width: 100%;

  ${vw('font-size', 14, 18)}
  ${vw('letter-spacing', 5, 7)}
  ${vw('padding-bottom', 8, 15, 20)}
  ${vw('margin-bottom', 16, 36, 0)}
  ${vw('margin-right', 192)};
  border-bottom: 1.5px dashed #4b4e52;

  @media ${media.desktop} {
    width: unset;
  }
`;

const TextItemWrapper = styled.ul`
  @media ${media.tablet} {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    flex: 2;
  }
`;

const TextItem = styled.li`
  color: ${theme.color.cc_grey};
  font-family: ${theme.font.sans_serif_family};
  ${vw('font-size', 11, 14)}
  ${vw('letter-spacing', 1, 2)}
  display: flex;
  align-items: center;
  cursor: pointer;
  ${('width', 454)};
  text-transform: uppercase;

  strong {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    ${vw('width', 16, 35)};
    ${vw('height', 16, 35)};
    ${vw('margin-right', 8, 18, 26)}
    border-radius: 50%;
  }

  @media ${media.tablet} {
    ${({ active }) =>
      active &&
      `
      strong {
        color: white;
        background-color: ${theme.color.cc_blue};
      }
    `}
  }
`;

const Item = styled.div`
  display: none;

  @media ${media.tablet} {
    display: flex;
    ${vw('width', 30)};
    ${vw('height', 30)};
    position: absolute;
    top: ${(props) => props.top};
    left: ${(props) => props.left};
    background-color: ${theme.color.cc_grey};
    border-radius: 50%;
    box-shadow: 0 5px 15px 0 ${theme.color.cc_grey};
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 2px solid white;
    transition: 0.2s all ease;
    &:hover {
      background-color: ${theme.color.cc_blue};
      -webkit-transform: scale(1.15);
      -ms-transform: scale(1.15);
      transform: scale(1.15);
    }
    ${({ active }) =>
      active &&
      `
        background-color: ${theme.color.cc_blue};
        -webkit-transform: scale(1.15);
        -ms-transform: scale(1.15);
        transform: scale(1.15);
    `}
  }
`;

const ItemLabel = styled.span`
  color: white;
  ${vw('font-size', 14)};
  font-family: ${theme.font.sans_serif_family};
  font-weight: 600;
`;

export default LandscapePlan;

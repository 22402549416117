import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import GhostButton from 'src/components/GhostButtonA';
import PhotoPortrait from 'src/components/PhotoPortrait';
import TreeRingB from 'src/assets/images/elements/tree_rings_2.svg';

import vw from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

function BannerPortrait(props) {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          <Watermark src={TreeRingB} />
          <PhotoPortrait src={props.image} />
          <Article>
            <h1>{props.title}</h1>
            <p className="body">
              {props.body}
            </p>
            <GhostButton
              href="/CC2_Features.pdf"
              download="CC2_Features.pdf"
              text={props.buttonLabel}
            />
          </Article>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  background-color: ${theme.color.cc_off_white_bg};
  ${vw('padding-top', 32, 60, 130)}
  ${vw('padding-bottom', 58, 105, 130)}
  ${vw('padding-left', 32, 61, 207)}
  ${vw('padding-right', 32, 173, 80)}
  z-index: 0;
  position: relative;

  @media ${media.desktop} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const Article = styled.div`
  ${vw('width', 256, 530, 400)}
  ${vw('margin-top', 16, 30, 112)}

  p {
    ${vw('margin-bottom', 16, 30, 40)}
  }
`;

const Watermark = styled.img`
  opacity: 0.15;
  position: absolute;
  ${vw('right', -110)}
  ${vw('top', -100)}
  ${vw('width', 370)}
  ${vw('height', 370)}
  z-index: 0;

  display: none;

  @media ${media.desktop} {
    display: unset;
  }
`;

export default BannerPortrait;

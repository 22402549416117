import React from 'react';

import Hero from './Hero';
import Banner from './Banner';
import TranquilBanner from './TranquilBanner';
import SanctuaryBanner from './SanctuaryBanner';
import ExpandBanner from './ExpandBanner';
import NatureBanner from './NatureBanner';

const Home = () => {
  return (
    <main>
      <Hero />
      <Banner />
      <TranquilBanner />
      <SanctuaryBanner />
      <ExpandBanner />
      <NatureBanner />
    </main>
  );
};

export default Home;

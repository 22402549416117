import React from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';

import PageHeroBanner from 'src/components/PageHeroBanner';
import LandscapePlan from './LandscapePlan';
import Banner from 'src/components/Banner';
import ScriptBanner from 'src/components/ScriptBanner';
import BannerPortrait from 'src/components/BannerPortrait';

import HeroImage from 'src/assets/images/features/hero.jpg';
import ScriptImage from 'src/assets/images/features/script.svg';
import BannerPhoto from 'src/assets/images/features/photo_yoga.jpg';

import vw from 'src/styles/utils';

const Features = () => {
  return (
    <Intl>
      {(t) => {
        return (
          <Root>
            <PageHeroBanner
              title={t.features.hero.title}
              body={t.features.hero.body}
              image={HeroImage}
            />
            <ScriptBanner image={ScriptImage} mobileHeight={49} />
            <LandscapePlan />
            <Banner
              title={t.features.banner1.title}
              videoSrc="https://player.vimeo.com/external/662714774.sd.mp4?s=7d3539de8d53fca6bb6dd080bc58957cdffd214f&profile_id=165"
            />
            <BannerPortrait
              body={t.features.banner2.body}
              image={BannerPhoto}
              buttonLabel={t.features.banner2.buttonLabel}
              buttonLink="/CC2_Features.pdf"
              buttonDownload="CC2_Features.pdf"
            />
          </Root>
        );
      }}
    </Intl>
  );
};

export default Features;

const Root = styled.main`
  background-color: #f8f8f6;
  ${vw('padding-top', 52, 80, 120)}
`;

import React from 'react';
import styled from 'styled-components';

import data from 'src/data/companies';

import Intl from 'src/components/Intl';
import TreeRingB from 'src/assets/images/elements/tree_rings_2.svg';

import vw, { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Partners = () => {
  return (
    <Intl>
      {(t) => (
        <Root>
          {data.map((company, index) => (
            <Company className="company">
              {/* Department */}
              <p className="body-italic department">
                {t.developer.companies[company.id].department}
              </p>

              {/* Name */}
              <h3>{company.name}</h3>

              {/* Logo */}
              <LogoWrapper>
                <LogoLink href={company.link} target="_blank">
                  <Logo
                    src={require(`src/assets/images/developer/${company.logo}`)}
                    height="50"
                    mobileHeight={company.logoHeightMobile}
                    desktopHeight={company.logoHeightDesktop}
                  />
                </LogoLink>
              </LogoWrapper>

              {/* Description */}
              <p className="body-sans-serif">
                {t.developer.companies[company.id].description}
              </p>
            </Company>
          ))}
          <Watermark src={TreeRingB} />
        </Root>
      )}
    </Intl>
  );
};

export default Partners;

const Root = styled.div`
  position: relative;
  ${vw('padding-left', 33, 61, 101)};
  ${vw('padding-right', 33, 61, 101)};

  .department {
    ${vw('margin-bottom', 16, 10)}
  }

  h3 {
    text-tranform: uppercase;
  }

  @media ${media.desktop} {
    display: flex;

    h3 {
      ${vw('font-size', 18)}
      ${vw('letter-spacing', 5)}
    }

    .company:nth-child(-n + 2) {
      margin-right: ${vwDesktop(32)};
    }
  }
`;

const Company = styled.div`
  ${vw('padding-top', 32, 60, 80)}
  ${vw('padding-bottom', 32, 60, 80)}
  flex: 1;
`;

const LogoWrapper = styled.div`
  ${vw('height', 117, 200)};
  ${vw('margin-bottom', 32, 30, 40)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1.5px dashed #4b4e52;
  border-bottom: 1.5px dashed #4b4e52;
`;

const Logo = styled.img`
  height: ${(props) => props.mobileHeight}px;

  @media ${media.tablet} {
    height: ${(props) => props.desktopHeight}px;
  }
`;

const LogoLink = styled.a``;

const Watermark = styled.img`
  opacity: 0.15;
  position: absolute;
  ${vw('right', -110)}
  ${vw('bottom', -255)}
  ${vw('width', 370)}
  ${vw('height', 370)}
  z-index: 0;
  display: none;

  @media ${media.desktop} {
    display: unset;
  }
`;

import React from 'react';
import styled from 'styled-components';
import MenuIcon from 'src/assets/images/icons/menu.svg';
import CloseIcon from 'src/assets/images/icons/close.svg';
import vw from 'src/styles/utils';

const MenuToggle = (props) => {
  const { menuOpen } = props;
  
  return (
    <Root src={menuOpen ? CloseIcon : MenuIcon} {...props} width="20" height="14"></Root>
  )
};

export default MenuToggle;

const Root = styled.img`
  position: absolute;
  left: 0;
  right: 0;
  ${vw('top', 18, 38, 0)};
  margin: auto;
  cursor: pointer;
`;

import React from 'react';
import styled from 'styled-components';

import GhostButton from 'src/components/GhostButton';
import PhotoLandscape from 'src/components/PhotoLandscape';
import ExpandRendering from 'src/assets/images/home/expand.png';
import Intl from 'src/components/Intl';

import vw from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

function ExpandBanner() {
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root>
          <PhotoLandscape src={ExpandRendering} />
          <Article>
            <h1>{t.home.banner3.title}</h1>
            <p className="body">{t.home.banner3.description}</p>
            <GhostButton
              to={`${getLocaleURL()}/features`}
              text={t.home.banner3.buttonLabel}
            />
          </Article>
        </Root>
      )}
    </Intl>
  );
}

const Root = styled.div`
  background-color: ${theme.color.cc_off_white};
  position: relative;
  ${vw('padding-top', 0)}
  ${vw('padding-bottom', 64, 120, 130)}
  ${vw('padding-left', 32, 60, 101)}
  ${vw('padding-right', 32, 60, 157)}

  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
  }

  .parallax {
    z-index: 0;
  }
`;

const Article = styled.div`
  ${vw('width', 256, 534, 400)}
  ${vw('margin-top', 16, 30, 112)}

  p {
    margin-right: 0;
    ${vw('margin-bottom', 40)}
  }
`;

// const Watermark = styled.img`
//   opacity: 0.2;
//   ${vw('width', 180, 240, 370)}
//   ${vw('height', 180, 240, 370)}

//   position: absolute;
//   ${vw('right', -79, -40, -119)}
//   ${vw('top', -57, -123, -145)}

//   @media ${media.desktop} {
//     z-index: 1;
//   }
// `

export default ExpandBanner;

import React from 'react';
import styled from 'styled-components';

import Intl from 'components/Intl';

import vw from 'src/styles/utils';
import theme from 'src/styles/theme';

const Privacy = () => {
  return (
    <Intl>
      {(t, { getLocaleURL }) => (
        <Root>
          <Wrapper>
            <h1>{t.privacy.title}</h1>
            <p>
              This Privacy Policy has been prepared by Ledingham McAllister for
              their required site (herein known as “Cedar Creek”, “we”, “us”,
              “our”) and sets out the way Ledingham McAllister collects,
              discloses, use, and otherwise manages personal information.  
              <br />
              <br />
              The Privacy Policy also describes the privacy practices on the
              Ledingham McAllister required site website (the “website”, the
              “site”, or the “websites”) and through other interactions with
              consumers.
            </p>
            <h2>Collection and Use of Personal Information</h2>
            <p>
              <span>Guest Registry:</span> When you visit one of our sales
              centres and complete a guest registry form, we collect contact
              information such as your first and last name, your mailing
              address, your email address, how you heard about us and your
              personal phone numbers. You may also choose to provide optional
              additional information such as your current housing situation,
              your reasons for moving, where you currently reside, your desired
              housing characteristics, family status, age group, number of
              adults and children and your annual household income.   
              <br />
              <br />
              We only use this information that you have provided on the Guest
              Registry to form a better understanding of your needs, aid you in
              finding a suitable home and to send you tailored communications
              about our developments, products and services.   
              <br />
              <br />
              We also use this information on an aggregator basis to help us
              better understand our customers and to improve our products and
              service offerings.  <br />
              <br />
              <span>Buying a Home:</span> When you complete a CPS (Contract of
              Purchase and Sale) for a residential property through Ledingham
              McAllister at any of our developments, we will collect the
              information you provided on the Agreement including the first and
              name and contact information (such as telephone numbers and
              mailing address) for the purchasers, including the property
              description, and purchase amount. We utilize this information for
              the purposes of facilitating the residential property transaction.
                
              <br />
              <br />
              Additionally, we will collect pertinent information to verify the
              identity of each purchaser (such as a valid government-issued
              piece of identification) as well as a personal cheque for deposit
              purposes and as required by law, REDMA and Fintrac rules,
              regulations and policies.  <br />
              <br />
              <span>Warranty Registration and Requests:</span> We collect
              certain pieces of personal information, including your name, email
              address, mailing address, telephone numbers and property closing
              date when you register for a new home warranty plan in your
              respective and applicable province or when you make a warranty
              customer care request. This information will be used to complete
              the warranty registration and to fulfill your warranty request.  
              <br />
              <br />
              <span>Website Registration:</span> In order to obtain access to
              information regarding products and services on any
              password-protected areas of our websites, we may request certain
              personal information such as your name, telephone number, mailing
              address, email address and the corresponding password that you
              have selected. We use this information to provide and administer
              your online account or service your request.  <br />
              <br />
              <span>Marketing Communications:</span> When you complete and
              submit a guest registration form, or otherwise sign-up to receive
              information regarding our developments or related products and
              services, we collect your contact information such as your name,
              phone numbers, mailing addresses and email address. We will use
              this information to send you communications based on your
              expressed interests by mail, email or telephone. You may opt-out
              of receiving all future marketing and promotional communications
              at any time by clicking on the unsubscribe link included in our
              email communications, or by contacting us with the credentials
              noted at the bottom of this Privacy Policy.   
              <br />
              <br />
              <span>Customer Service:</span> Any time that you contact us with a
              comment, complaint or a question, you may be asked for information
              to help us identify yourself (such as your name, address, and your
              telephone number) along with additional information we may need to
              help us promptly answer your question or respond to your comment
              or complaint (e.g: your lot number, warranty enrolment number,
              development, community, etc.) We may retain this information to
              assist you in the future and to improve our customer service and
              product and service offerings. We may additionally use personal
              information to establish and manage our relationship with you and
              provide quality customer service.
            </p>
            <h2>Disclosure and Sharing of Your Personal Information</h2>
            <p>
              We will not under any circumstances disclose, rent, trade, sell or
              otherwise transfer your personal information without your consent,
              except as otherwise outlined herein.
              <br />
              <br />
              <span>Service Providers:</span> Your personal information may be
              transferred (or otherwise made available) to our designated third
              parties or affiliates who provide services on our behalf. As an
              example, we may utilize a service provider for the maintenance of
              our website, including hosting an information form, providing
              additional services related to our site, sending electronic mail
              or other functions related to our business or services provided.  
              <br />
              <br />
              Our service providers are given only the information they need to
              perform designated functions, and are not authorized under any
              circumstances to disclose personal information for their own
              marketing purposes.  <br />
              <br />
              Your personal information may be maintained and processed by us,
              our affiliations and other third-party service providers in
              Canada, the US or other foreign jurisdictions. In the event that
              personal information is transferred to other foreign
              jurisdictions, it will be subject to the laws of that country and
              may be disclosed to or accessed by their respective courts of law
              (or related parties), local law enforcement and governmental
              authorities in accordance to their laws.  <br />
              <br />
              <span>Legal/Compliance:</span> Ledingham McAllister, its
              affiliates, sister companies and its Canadian or foreign service
              providers may provide your personal information in response to a
              search warrant or other legally valid order or inquiry to another
              organization for the purposes of investigating any breaches of an
              agreement, violation of a law or detecting, preventing or
              suppressing fraud, or as otherwise permitted or required by
              applicable Canadian or other foreign laws. Your personal
              information may also be disclosed where necessary for the
              establishment, exercise or defense of legal claims and to prevent
              or investigate loss or harm to persons or property.  <br />
              <br />
              <span>Sale of Business:</span> Personal information may be
              provided to third parties in connection with a prospective or
              completed business transaction, including a sale or merger
              (including transfers made as part of bankruptcy proceedings or
              insolvency) involving all or a part of Ledingham McAllister or as
              a part of a corporate reorganization or stock sale or additional
              or other changes in corporate control.
            </p>
            <h2>
              Internet-Based Advertising, Information About our Website and
              Cookies
            </h2>
            <h3>overview</h3>
            <p>
              We and/or our service providers collect IP (Internet protocol)
              addresses from all visitors to the website and other related
              information such as browser type, operating system, page requests
              and an average time spent visiting our website.  <br />
              <br />
              This information is only used to enable us to understand our
              site’s activity and to improve and monitor the website.
            </p>
            <h3>TRACKING TAGS, WEB BEACONS AND COOKIES</h3>
            <p>
              Our site uses a technology called ’web beacons’ and ‘tracking
              tags’. These systems allow us to understand which pages you
              frequent on our site. Tracer tags like these are utilized to help
              us learn how we can tailor and optimize our website for yourself
              and other site visitors.  <br />
              <br />
              Our website utilizes a technology known as ‘cookies.’ Cookies are
              essentially pieces of data that sit on your hard drive so we can
              recognize you again when you return to our site.  <br />
              <br />
              We utilize cookies on the sections of our site in which you are
              prompted to log in or have boxes which are customizable. In the
              event that you have registered on our site, these cookies will
              allow us to identify who you are and which browser you are using
              and will allow us to recognize that you have previously visited
              the site and it is may be necessary to access your account
              information (which is automatically stored on our computers) in an
              effort to personalize services and deliver products.  <br />
              <br />
              Cookies provide us and our service providers with information
              pertaining to your utilization of our site (e.g: which sections of
              the site you have frequented) that will then be used to
              personalize our site in accordance with your designated interests
              and preferences.  <br />
              <br />
              Our site is not designed to respond to ‘do not track’ signals that
              are received from browsers.
            </p>
            <h3>INTERNET-BASED ADVERTISING</h3>
            <p>
              You may find our advertisements on other websites through the
              usage of our third parties, such as Facebook. These companies may
              additionally use web beacons, tracer tags, cookies to report
              understand certain information surrounding your visits to our site
              and other sites you visit and/or interact to ads, in order to
              measure the effectiveness of our marketing campaigns and to
              deliver the appropriate ads which are relevant to you on and off
              our website.  <br />
              <br />
              If you would like to learn additional information about the
              collection and use of information by third parties for behavioural
              purpose. Additionally, if you would like to opt-out of certain
              third-party cookies or selection what online advertisements you
              choose to receive, you may do so by visiting the Digital
              Advertising Alliance of Canada{' '}
              <a
                href="http://youradchoices.ca/choices/"
                target="_blank"
                rel="noopener noreferrer"
              >
                website
              </a>
              .  <br />
              <br />
              If you would like opt-out of internet-based ads from Google, you
              may do so{' '}
              <a
                href="https://adssettings.google.com/authenticated"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
            <h3>ANALYTICS</h3>
            <p>
              We may utilize services from a third party such as Google
              Analytics to aid us in obtaining and analyzing information about
              the sections of our site which you frequent. These third parties
              may use technologies as noted above including: web beacons, web
              server logs and cookies. This information will be used to improve
              and evaluate your customer experience and the simplicity and
              convenience of our website, in addition to aiding us in
              understanding the specific ways in which you use our site based on
              your website visits. If you would like further information
              regarding how Google uses data when on a patterns’ sites or
              applications, you may do so{' '}
              <a
                href="https://policies.google.com/technologies/partner-sites"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>
              .
            </p>
            <h3>Google ANALYTICS</h3>
            <p>
              Google Analytics is a web analytics service offered by Google that
              tracks and reports website traffic. Google uses the data collected
              to track and monitor the use of our service. This data is shared
              with other Google services. Google may use the collected data to
              contextualize and personalize the ads of its own advertising
              network.   
              <br />
              <br />
              You can opt-out of having made your activity on the service
              available to Google Analytics by installing the Google Analytics
              opt-out browser add-on. The add-on prevents the Google Analytics
              JavaScript (ga.js, analytics.js, and dc.js) from sharing
              information with Google Analytics about visits activity.   
              <br />
              <br />
              For more information on the privacy practices of Google, please
              visit the Google Privacy &amp; Terms web page: 
              <a
                href="https://policies.google.com/privacy?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://policies.google.com/privacy?hl=en
              </a>
            </p>
            <h3>Facebook Advertisements</h3>
            <p>
              Facebook offers advertising that targets users based on designed
              audiences and specific objectives. Facebook collects this data
              based on provided and approved user parameters.  <br />
              <br />
              Alternatively, ads may target you based off of data from your
              browser through the utilization of algorithms. Facebook may use
              the collected data to contextualize and personalize the ads of its
              own advertising network.  <br />
              <br />
              You can opt-out of ads by going to your Facebook profile,
              selecting settings, ad, and then making modification in the Ad
              settings (Ads based on data from partners) by selecting ‘Not
              Allowed.’
            </p>
            <h3>social media</h3>
            <p>
              This site may provide you with the opportunity to engage with our
              content on or through third-party social networking sites,
              applications or plug-ins. When you engage with our content on or
              through third-party operated social networking websites,
              applications or plug-ins, you may allow us to have access to
              certain information that is associated with your social media
              accounts (Username, email, name) to deliver the content or as a
              part of the operation of the website, application or plug-in. When
              you provide information from your social media account, we may
              utilize this information to personalize your experience on our
              site and on the third-party social networking websites,
              applications or plug-ins and to provide you with other services or
              products you may request.
            </p>
            <h3>THIRD PARTY LINKS</h3>
            <p>
              Our site may contain links to other sites that are not owned or
              operated by us, this includes social media websites. The links to
              our website may be featured on third party websites on which we
              advertise on. Except as you provided herein, we will not under any
              circumstances provide any of your personal information to these
              third parties without your explicit consent. These links are
              provided to third party websites as a convenience to you as the
              site user. Links are not intended to act as an endorsement of or a
              referral to the linked sites. These linked sites have independent
              and separate privacy statements, terms of use and notices which we
              advise you to read very carefully. We do not have any control over
              these websites, and as such, have no liability or responsible for
              the manner in which these organizations that operate these linked
              websites may use, disclose, collect, secure or otherwise treat
              your personal information.
            </p>
            <h2>Retention and Safeguards</h2>
            <p>
              We have applied reasonable administrative, technical and physical
              measures in an effort to protect the personal information in our
              custody and control against loss, theft and unauthorized access
              including the usage, modification and disclosure of information.
              We restrict access to your personal information on a need to know
              basis to employees and authorized service providers who need
              access in order to fulfill their job requirements.  <br />
              <br />
              Your online access to your personal information may be protected
              with a password that you have selected. We strongly discourage you
              from disclosing or sharing your password with anyone. We will
              never prompt you for your password via any unsolicited
              communication (such as phone calls, email, phone calls, or social
              media messaging systems).  <br />
              <br />
              Our personal information retention processes are meant to retain
              personal information of our customers for no longer than necessary
              for the purposes stated above or to otherwise adhere to legal
              parameters.
            </p>
            <h3>Access to Your Personal Information</h3>
            <p>
              You retain the right to access, update and correct inaccuracies in
              your personal information that we have in our custody and control.
              This is subject to personal exceptions as prescribed by the law.  
              <br />
              <br />
              You may request access to update, modify and correct inaccuracies
              in personal information that have in our possession or control by
              emailing, writing us, or calling us through the contact
              information as noted below.  <br />
              <br />
              We may in turn request certain information for verification
              purposes in order to properly identify you as the appropriate
              person seeking access to their personal information records.
            </p>
            <h3>CHANGES TO THE PRIVACY POLICY</h3>
            <p>
              This privacy policy may be updated periodically to reflect changes
              to our personal information practices in accordance with the law.
              We will post the updated Privacy Policy on our website; your
              personal information will always be treated in accordance to what
              is noted within the Privacy Policy in place at the time your
              personal information was collected, unless you otherwise consent.
            </p>
            <h3>CONTACT US</h3>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us:  <br />
              <br />
              <br />
              By email:{' '}
              <a href="mailto:admin@liveatcedarcreek.com">
                admin@liveatcedarcreek.com
              </a>
              <br />
              <br />
              By visiting this page on our website:{' '}
              <a href="https://liveatcedarcreek.com">
                https://liveatcedarcreek.com
              </a>
              <br />
              <br />
              By phone number: <a href="tel:6045298868">604.529.8868</a>
            </p>
          </Wrapper>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.main`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${vw('padding-top', 40, 40)}
  ${vw('padding-bottom', 80, 120)}
  ${vw('padding-right', 16, 60, 160)}
  ${vw('padding-left', 16, 60, 160)}
  h1 {
    font-family: ${theme.font.sans_serif_family};
    font-weight: 300;
    text-align: center;
    ${vw('font-size', 22, 32)};
    ${vw('letter-spacing', 0.55, 0.8)};
    ${vw('line-height', 32, 40)};
    ${vw('margin-bottom', 40, 40)};
  }
  h2 {
    font-family: ${theme.font.sans_serif_family};
    font-weight: normal;
    letter-spacing: normal;
    text-transform: none;
    ${vw('font-size', 18, 24)};
    ${vw('line-height', 24, 32)};
    ${vw('margin-top', 40, 40)};
    ${vw('margin-bottom', 16, 16)};
  }
  h3 {
    font-family: ${theme.font.sans_serif_family};
    font-weight: 500;
    text-transform: uppercase;
    ${vw('font-size', 14, 16)};
    ${vw('line-height', 22, 26)};
    ${vw('letter-spacing', 0.44, 0.5)};
    ${vw('margin-top', 16, 16)};
    ${vw('margin-bottom', 8, 8)};
  }
  p {
    font-family: ${theme.font.sans_serif_family};
    font-weight: 500;
    ${vw('font-size', 13, 14)};
    ${vw('letter-spacing', 0.32, 0.35)};
    ${vw('line-height', 20, 22)};
    span {
      font-weight: 600;
    }
  }
  a {
    color: ${theme.color.cc_grey};
    transition: 0.4s ease;
    text-decoration: underline;
    &:hover {
      opacity: 0.75;
    }
  }
`;

const Wrapper = styled.div`
  ${vw('margin-top', 60, 120, 120)}
`;

// const Logo = styled.img`
//   display: block;
//   ${vw('width', 150, 223)}/* ${vw('margin-bottom', 80, 200, 120)} */
// `;

export default Privacy;

import React, { useState } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

// Components
import Spotlight from './Spotlight';

// Icons
import ArrowLeft from 'src/components/icons/ArrowLeft';
import ArrowRight from 'src/components/icons/ArrowRight';

// Styles
import vw from 'src/styles/utils';
import theme from 'src/styles/theme';
import media from 'src/styles/media';

// Slick styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Slick = (props) => {
  const [showSpotlight, setShowSpotlight] = useState(false);
  const [activeImage, setActiveImage] = useState(0);

  const openSpotlight = (i) => {
    setActiveImage(i);
    setShowSpotlight(true);
  };

  const closeSpotlight = () => setShowSpotlight(false);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Root>
      {showSpotlight && (
        <Spotlight
          title={props.title}
          closeHandler={closeSpotlight}
          images={props.images}
          activeImage={activeImage}
        />
      )}
      <Title>{props.title}</Title>
      <Slider {...settings}>
        {props.images.map((image, index) => (
          <Card key={index} onClick={() => openSpotlight(index)}>
            <CardContent>
              <CardImage className="card-image" bg={image.image}></CardImage>
              <Svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="15"
                  cy="15"
                  r="14"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="square"
                />
                <path
                  d="M15.3718 18.6176V15.3719H18.6176V14.2458H15.3718V11H14.2458V14.2458H11V15.3719H14.2458V18.6176H15.3718Z"
                  fill="white"
                />
              </Svg>
            </CardContent>

            <p className="caption">{image.caption}</p>
          </Card>
        ))}
      </Slider>
    </Root>
  );
};

const Root = styled.div`
  ${vw('padding-left', 32, 60, 100)}
  ${vw('padding-right', 32, 60, 100)}
  ${vw('padding-top', 32, 60)}
  ${vw('padding-bottom', 32, 60)}

  .slick-slider {
    ${vw('margin-left', 0, -15)}
    ${vw('margin-right', 0, -15)}
  }
`;

const Title = styled.h3`
  font-weight: 300;
  text-transform: uppercase;
  color: ${theme.color.cc_grey};
  ${vw('margin-bottom', 16, 30)}
  ${vw('font-size', 18)}
  ${vw('line-height', 24)}
  ${vw('letter-spacing', 7)}
  text-align: center;

  @media ${media.tablet} {
    text-align: left;
  }
`;

const Card = styled.div`
  ${vw('padding-left', 0, 15)}
  ${vw('padding-right', 0, 15)}
  text-align: center;

  @media ${media.tablet} {
    text-align: left;
  }
`;

const CardContent = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  ${vw('height', 180, 200)}
  ${vw('margin-bottom', 8, 12)}
  filter: drop-shadow(0px 2px 8px rgba(75, 78, 82, 0.4008));

  &:focus,
  &:hover {
    .card-image {
      transform: scale(1.1);
    }

    svg circle {
      fill: ${theme.color.cc_blue};
    }
  }
`;

const Svg = styled.svg`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  transform: translateY(-25%);
  ${vw('width', 30, 30, 40)}
  ${vw('height', 30, 30, 40)}

  circle {
    transition: all 0.8s ease 0s;
  }
`;

const CardImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.bg});
  background-position: center;
  background-size: cover;
  transition: all 0.8s ease 0s;

  &:hover {
  }
`;

export default Slick;

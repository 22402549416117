import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';
import theme from 'src/styles/theme';

const List = (props) => {
  const { activeCategory, activePoint, handleCategory, handlePoint, data } =
    props;
  return (
    <Root>
      {data.map((category, index) => (
        <Category key={index}>
          <Title onClick={() => handleCategory(index)}>
            <h4
              className={`neighbourhood-categories ${
                activeCategory === index ? 'blue' : 'grey'
              }`}
            >
              {category.title}
            </h4>
            <svg
              width="9px"
              height="9px"
              viewBox="0 0 9 9"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              style={{
                opacity: activeCategory === index ? 1 : 0.42,
                transform:
                  activeCategory === index ? 'rotate(-315deg)' : 'none',
              }}
            >
              <title>+</title>
              <g
                id="Touchscreen-Design-V2-FINAL"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <polygon
                  id="+"
                  fill={activeCategory === index ? '#00aec7' : '#4b4e52'}
                  fillRule="nonzero"
                  points="5.16521739 9 5.16521739 5.16521739 9 5.16521739 9 3.83478261 5.16521739 3.83478261 5.16521739 0 3.83478261 0 3.83478261 3.83478261 0 3.83478261 0 5.16521739 3.83478261 5.16521739 3.83478261 9"
                ></polygon>
              </g>
            </svg>
          </Title>
          <Wrapper active={activeCategory === index}>
            {category.points.map((point, p) => (
              <Point
                key={p}
                active={activePoint === p}
                onClick={() => handlePoint(p)}
              >
                <span
                  className={`poi-number ${
                    activePoint === p ? 'white' : 'grey'
                  }`}
                >
                  {p + 1}
                </span>
                <p
                  className={`poi-label ${activePoint === p ? 'blue' : 'grey'}`}
                >
                  {point.text}
                </p>
              </Point>
            ))}
          </Wrapper>
        </Category>
      ))}
    </Root>
  );
};

List.propTypes = {
  activeCategory: PropTypes.number,
  activePoint: PropTypes.number,
  handleCategory: PropTypes.func,
  handlePoint: PropTypes.func,
  data: PropTypes.array,
};

const Root = styled.div`
  width: 100%;
`;

const Category = styled.div`
  width: 100%;
`;

const Wrapper = styled.div`
  overflow: hidden;
  max-height: ${(props) => (props.active ? vw(392) : 0)};
  transition: 0.4s ease;
`;

const Title = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${vw(60)};
  padding-right: ${vw(15)};
  border-bottom: ${vw(1.5)} dotted ${theme.color.grey};
  h4 {
    transition: 0.4s ease;
  }
  svg {
    width: ${vw(8.3)};
    display: block;
    transition: 0.4s ease;
  }
  polygon {
    transition: 0.4s ease;
  }
`;

const Point = styled.div`
  display: flex;
  align-items: center;
  &:first-of-type {
    margin-top: ${vw(16)};
  }
  &:last-of-type {
    margin-bottom: ${vw(16)};
  }
  p {
    white-space: nowrap;
    transition: 0.4s ease;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${vw(40)};
    height: ${vw(40)};
    border-radius: 50%;
    margin-right: ${vw(20)};
    background-color: ${(props) =>
      props.active ? theme.color.cc_blue : 'transparent'};
    transition: 0.4s ease;
  }
`;

export default List;

import React from 'react';

export default {
  pages: {
    floorplans: 'floorplans',
    interiors: 'interiors',
    features: 'features',
    neighbourhood: 'neighbourhood',
    gallery: 'gallery',
    developer: 'developer',
    register: 'register',
  },
  bookAppt: 'Book Appointment',
  contact: 'Contact',
  home: {
    nowSelling: 'FINAL HOME REMAINING, MOVE IN TODAY',
    banner1: {
      title: 'THE SIGNATURE COLLECTION',
      description:
        'With the same attention to detail found next door, the final phase of homes at Cedar Creek have been carefully considered with a fresh and modern finish. Ranging in size from one bedroom + den and three bedroom homes, the signature collection will suit a variety of lifestyles, whether your family is growing or getting smaller, you’re finally home.',
      buttonLabel: 'View Floorplans',
    },
    banner2: {
      title: 'TRANQUIL BY NATURE, WELCOME HOME',
      description: (
        <>
          Inspired by the rugged and majestic British Columbia Cedar, The
          Signature Collection by Ledingham McAllister welcomes you home with
          contemporary west coast design and convenient living in the heart of
          Burnaby’s Edmonds Neighborhood.
          <br />
          <br />
          Homes at Cedar Creek feature gorgeous wood frame suites with over
          height ceilings and bright open concept living with generous outdoor
          space.
        </>
      ),
      buttonLabel: 'View Interiors',
    },
    banner3: {
      title: (
        <>
          EXPAND YOUR
          <br /> OUTLOOK
        </>
      ),
      description:
        'All homes at Cedar Creek expand your living area with generous outdoor spaces that you can enjoy year-round. Whether it’s a sprawling ground floor patio or a private balcony on the upper floor, there’s plenty of room for your BBQ and an outdoor dining area.',
      buttonLabel: 'EXPLORE FEATURES',
    },
    banner4: {
      title: (
        <>
          THE NATURE <br />
          OF BURNABY
        </>
      ),
      description:
        "The Signature Collection at Cedar Creek places you at the front door of Burnaby's vast network of trails, parks, lakes and forests for a wide variety of outdoor pursuits. Whether a casual stroll, vigorous bike expedition or gentle canoe excursion, you will never lack inspiration.",
      buttonLabel: 'Neighbourhood',
    },
    backHome: 'back to homepage',
  },
  floorplans: {
    header: {
      plan: 'Plan',
      type: 'Type',
      sqft: 'Aprox. Sq Ft',
    },
    welcome: {
      title: (
        <>
          Welcome <br className="hide-on-mobile" />
          Home
        </>
      ),
      body: 'A WIDE RANGE OF ONE-BEDROOM + DEN AND THREE-BEDROOM HOMES HAVE A COMBINATION OF STYLE, FORM AND FUNCTION IN A PLACE YOU ARE ALWAYS PROUD TO CALL HOME.',
    },
    types: {
      oneBedOneDen: 'One Bedroom & Den',
      twoBed: 'Two Bedroom',
      threeBed: 'Three Bedroom',
    },
    downloadPlan: 'Download Plan',
  },
  interiors: {
    hero: {
      title: <>YOUR INDIVIDUAL HOME</>,
      body: 'Over-height ceilings and expansive windows maximize light and open living areas to suit your own personal lifestyle. These are the details that turn your home into your own private sanctuary. ',
    },
    banner1: {
      title: (
        <>
          Heart <br />
          of the home
        </>
      ),
      body: 'The best gatherings usually revolve around food, from appetizers to full-course meals, and these kitchens are designed for entertaining. Most homes include pantries to ensure you are always fully stocked, while islands and breakfast bars are perfect for food prep or casual dining. These kitchens don’t require compromise; they invite the creation of lasting memories.',
    },
    banner2: {
      title: 'PRIDE OF OWNERSHIP',
      body: (
        <>
          <li>
            West Coast contemporary residences designed by award-winning Integra
            Architecture
          </li>
          <li>
            Your choice of two designer palettes curated by Portico Design Group
          </li>
          <li>Airy 9’ ceilings maximize light and flow of living</li>
          <li>Lush and extensive landscaping and gardens throughout </li>
          <li>
            Dedicated customer service team ensures that living in your home is
            as worry free as possible
          </li>
        </>
      ),
      buttonLabel: (
        <>
          <span className="hide-on-mobile">Download</span>
          <span className="show-on-mobile">Get</span> Features Sheet
        </>
      ),
    },
  },
  features: {
    hero: {
      title: 'Expand your outlook',
      body: "RESIDENTS AT CEDAR CREEK RECEIVE PRIVATE ACCESS TO IMPRESSIVE INDOOR AND OUTDOOR AMENITIES YOU WILL LOVE WITHOUT THE COMMUTE. WHETHER YOU'RE ACHIEVING YOUR FITNESS GOALS, ENTERTAINING FRIENDS AND FAMILY, OR HOSTING OVERNIGHT VISITORS.",
    },
    banner1: {
      title: 'GENEROUS OUTDOOR SPACES THAT YOU CAN ENJOY YEAR ROUND',
    },
    banner2: {
      body: 'Whether you’re unwinding in your private outdoor space or relaxing in the expansive community outdoor space, the amenities at Cedar Creek connect your living space into the beautiful outdoors.',
      buttonLabel: (
        <>
          <span className="hide-on-mobile">Download</span>
          <span className="show-on-mobile">Get</span> Features Sheet
        </>
      ),
    },
    landscape: {
      title: 'Landscape Plan',
      labels: {
        entrance: 'Entrance',
        bikeRacks: 'Bike Racks',
        parkadeEntrance: 'Parkade Entrance',
        childrensPlayArea: 'Children’s Play Area',
        picnicTables: 'Picnic Tables',
        gardenPlots: 'Garden Plots',
      },
    },
  },
  neighbourhood: {
    hero: {
      title: (
        <>
          The <br /> Nature <br />
          of Burnaby
        </>
      ),
      body: 'Cedar Creek places you at the front door of Burnaby’s vast network of trails, parks, lakes, and forests for a wide variety of outdoor pursuits. ',
    },
    banner1: {
      title:
        "Convenient living in the heart of Burnaby's Edmonds neighbourhood",
    },
    banner2: {
      body: 'While conveniently nestled in the heart of an established residential neighbourhood, Cedar Creek is within walking distance to Burnaby’s abundance of boutique shops, entertainment, modern recreation, and transit. Cedar Creek offers a fresh new community with all the amenities of a big city. ',
    },
    map: {
      categories: {
        parks: 'Parks & Recreation',
        restaurants: 'Restaurants & Cafes',
        education: 'Education',
        shopping: 'Shopping',
        fitness: 'Fitness',
        transit: 'transit',
      },
    },
  },
  gallery: {
    hero: {
      title: 'West Coast Inspired',
      caption: 'The Signature Collection - Phase 2',
      body: 'An impressive sense of arrival is reflected in our attention to architectural detail. From lushly landscaped gardens to grand covered entryways that lead to inviting lobbies with fireside seating, every aspect of Cedar Creek has been considered to ensure you instantly feel at home.',
    },
    banner1: {
      title: 'Interiors',
      captions: {
        kitchen: 'kitchen',
        dining: 'dining',
        living: 'living',
        office: 'office',
        bedroom: 'bedroom',
        bathroom: 'bathroom',
      },
    },
    banner2: {
      title: 'Neighbourhood',
    },
  },
  developer: {
    hero: {
      title: 'CONFIDENCE.  QUALITY.  VALUE',
      body: 'At Ledingham Mcallister we are dedicated to smart development by creating superior new home communities in great locations. For over a century it has been our commitment to exacting standards of design, environmental leadership, engineering and construction that ensures homes of enduring quality and value. More than 18,000 homes later, we can proudly state that our past was built on it, and our future depends on it.',
    },
    companies: {
      integra: {
        department: 'Architecture',
        description: (
          <>
            Integra Architecture Inc. is an award-winning architectural firm
            located in the heart of Vancouver. The firm has a talented and
            evolving team of professionals who value strong client
            relationships, effective communication, and high-quality,
            sustainable design. The diverse portfolio includes all types of
            residential buildings, mixed-use projects and seniors housing.
            <br />
            <br />
            Through communication and high-quality construction drawings,
            Integra seamlessly bridges the gap between the client’s reality and
            the architect’s vision.
          </>
        ),
      },
      portico: {
        department: 'Interior Design',
        description: (
          <>
            Our approach has always been driven by the desire to “bring space to
            life”. By creating dramatic interiors, with a sense of discovery,
            people become engaged and interactive with the spaces they use
            daily. We design interiors to be true to the project vision: the
            architecture, the landscape, the geography and to the people who
            will be involved with them. We love to challenge the expected.
            <br />
            <br />
            Originally founded in 1992, we provide design services to both
            commercial and residential clients, resort operators and residential
            and commercial real estate developers.
          </>
        ),
      },
      prospect: {
        department: 'Landscape Architect',
        description:
          'Prospect & Refuge is a full-service landscape architecture firm based in Vancouver, BC. From multi-family housing to schools, childcare facilities, hospitals and industrial sites, we work closely with clients to create vibrant and memorable places that are cohesive with the community. Building on over 25 years of history as Jonathan Losee Ltd., Prospect & Refuge carries on Jon’s legacy of humour, client-oriented service, environmental focus, and technical attention to detail.',
      },
    },
  },
  footer: {
    column1: {
      title: 'Contact Us',
      buttonLabel: 'Register Now',
    },
    column2: {
      title: 'Presentation Centre',
      text: (
        <>
          Call To Book An Appointment
          <br />
          7166 14th Ave, Burnaby, BC
        </>
      ),
      buttonLabel: 'Book Appointment',
    },
    column3: {
      title: 'Site Location',
    },
    marketing: 'marketing in association with',
    disclaimer:
      'The information contained in this promotional material is for illustrative purposes only and is subject to change without notice. Maps and renderings are an artistic representation only. The quality residences at Cedar Creek 2 are developed by LM Cedar Creek Homes Limited Partnership. The developer reserves the right to make modifications or substitutions should they be necessary. This is currently not an offering for sale. Any such offering can only be made by way of a disclosure statement. E. & O.E.',
    corporate: {
      title: 'Confidence. Quality. Value',
      text: 'At Ledingham McAllister, we are dedicated to creating quality homes in superior locations and providing our customers with long-term value. We are committed to the highest standards in everything we do. Our past was built on it, our future depends on it.',
      privacy: 'Privacy Policy',
    },
  },
  register: {
    title: 'Register Now',
    firstname: 'First Name *',
    lastname: 'Last Name *',
    email: 'Email Address *',
    phone: 'Phone Number *',
    zip: 'Postal/Zip Code *',
    select: 'Select One...',
    howHeard: 'How did you hear about us? *',
    howHeardOptions: [
      {
        label: 'Online Ads',
        value: 'Online Ads',
      },
      {
        label: 'Chinese Ads',
        value: 'Chinese Ads',
      },
      {
        label: 'Korean Ads',
        value: 'Korean Ads',
      },
      {
        label: 'Print Advertising',
        value: 'Print Advertising',
      },
      {
        label: 'Social Media',
        value: 'Social Media',
      },
      {
        label: 'Signage',
        value: 'Signage',
      },
      {
        label: 'Realtor',
        value: 'Realtor',
      },
      {
        label: 'Friends & Family',
        value: 'Friends & Family',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
    whatInquire: 'What are you inquiring about?',
    whatInquireOptions: [
      {
        label: 'One bedroom',
        value: 'One bedroom',
      },
      {
        label: 'Two bedroom',
        value: 'Two bedroom',
      },
      {
        label: 'Three bedroom',
        value: 'Three bedroom',
      },
      {
        label: 'Penthouses',
        value: 'Penthouses',
      },
    ],
    realtor: 'Are you a realtor? *',
    workingWithRealtor: 'Are you working with a realtor?',
    yes: 'Yes',
    no: 'No',
    ifYesRealtor: `If you are a realtor or working with a realtor, please indicate your realtor's name or brokerage:`,
    disclaimer:
      'I consent to receiving communications from sales and marketing.',
    submit: 'submit your registration',
  },
  thankYou: {
    title: 'Thank You',
    body: 'Thank you for registering for The Signature Collection by Ledingham McAllister. Your submission has been received and a representative will be in touch with you soon.',
  },
  notFound: {
    title: (
      <>
        Sorry! <br />
        We apologize
      </>
    ),
    subtitle: '404 Not Found',
    body: (
      <>
        Looks like something went wrong here. <br />
        The page you are looking for does not exist.
      </>
    ),
  },
  privacy: {
    title: 'Privacy Policy',
  },
};

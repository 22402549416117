import React from 'react';
import styled from 'styled-components';

import Welcome from './Welcome';
import FloorplansTable from './FloorplansTable';

import { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Floorplans = () => {
  return (
    <Root>
      <FloorplansTable />
      <Welcome />
    </Root>
  );
};

const Root = styled.main`
  display: flex;
  flex-direction: column-reverse;

  @media ${media.desktop} {
    flex-direction: row;
    justify-content: space-between;
    padding-left: ${vwDesktop(100)};
    padding-right: ${vwDesktop(100)};
  }
`;

export default Floorplans;

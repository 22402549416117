import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Element } from 'react-scroll';

import Project from './sections/Project';
import Corporate from './sections/Corporate';

// This is meant to be used as the global footer for all LedMac projects. It includes a project specific footer and a global corporate footer.

const Footer = (props) => {
  const {
    color,
    bgImage,
    logo,
    project,
    column1,
    column2,
    column3,
    disclaimer,
  } = props;
  return (
    <Element name="footer">
      <Root>
        <Project
          bgImage={bgImage}
          color={color}
          logo={logo}
          project={project}
          column1={column1}
          column2={column2}
          column3={column3}
        />
        <Corporate disclaimer={disclaimer} />
      </Root>
    </Element>
  );
};

Footer.propTypes = {
  color: PropTypes.string,
  logo: PropTypes.string,
  project: PropTypes.string,
  disclaimer: PropTypes.string,
  column1: PropTypes.object,
  column2: PropTypes.object,
  column3: PropTypes.object,
};

const Root = styled.footer`
  position: relative;
  z-index: 0;
`;

export default Footer;

import React from 'react';
import Page from 'src/components/Page';

import Intl from 'src/components/Intl';

const ThankYou = () => {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Page
          title={t.thankYou.title}
          body={t.thankYou.body}
          homeButton={true}
        >
        </Page>
      )}
    </Intl>
  );
};

export default ThankYou;

import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import FloorplansWrapper from './FloorplansWrapper';
import TableHeader from './TableHeader';
import Toggle from './Toggle';

// Styles
import { vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const FloorplansTable = () => {
  const [phaseShowing, setPhaseShowing] = useState(2);

  const toggleHandler = (phase) => {
    setPhaseShowing(phase);
  };

  return (
    <Root>
      <Toggle
        onText={
          <>
            <span className="hide-on-mobile">The signature collection </span>
            phase 1
          </>
        }
        offText={
          <>
            <span className="hide-on-mobile">The signature collection </span>
            phase 2
          </>
        }
        currentPhase={phaseShowing}
        clickHandler={toggleHandler}
      />
      <TableHeader />
      <FloorplansWrapper phase={phaseShowing} />
    </Root>
  );
};

const Root = styled.div`
  @media ${media.desktop} {
    width: ${vwDesktop(814)};
    margin-top: ${vwDesktop(120)};
    display: inline-block;
  }
`;

export default FloorplansTable;

import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import Hero from './Hero';
import Slick from './Slick';
import data from 'src/data/gallery';

import vw from 'src/styles/utils';

const Gallery = () => {
  return (
  <Intl>
  {(t, { getLocaleURL, locale }) => (
    <Root>
      <Hero />
      <Slick
        title={t.gallery.banner1.title}
        images={data.interiors}
      />
      <Slick
        title={t.gallery.banner2.title}
        images={data.neighbourhood}
      />
    </Root>
    )}
    </Intl>
  );
};

export default Gallery;

const Root = styled.div`
  background-color: #F8F8F6;
  ${vw('padding-top', 52, 80, 120)}
  ${vw('padding-bottom', 0, 0, 60)}
`;

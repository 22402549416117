import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import GhostButton from 'src/components/GhostButtonA';
import vw from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

function PrivateBanner() {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          <Video
            src="https://player.vimeo.com/external/662714784.sd.mp4?s=d6948779dcb97fe5e3ab4fd44c5f15b31b1b28ba&profile_id=165"
            autoPlay
            muted
            playsInline
            loop
          />
          <Article>
            <h1>{t.interiors.banner2.title}</h1>
            <p className="body">
              <ul>{t.interiors.banner2.body}</ul>
            </p>
            <GhostButton
              href="/CC2_Features.pdf"
              download="CC2_Features.pdf"
              text={t.interiors.banner2.buttonLabel}
            />
          </Article>
        </Root>
      )}
    </Intl>
  );
}

const Root = styled.div`
  background-color: ${theme.color.cc_off_white_bg};

  ${vw('padding-top', 0, 0, 130)}
  ${vw('padding-left', 32, 60, 207)}
  ${vw('padding-right', 32, 60, 101)}
  ${vw('padding-bottom', 60, 120, 160)}
  position: relative;

  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
`;

const Article = styled.div`
  ${vw('width', 260, 420, 460)}
  ${vw('margin-top', 16, 30, 0)}

  p {
    margin-right: 0;
    ${vw('margin-top', 16, 40)}
    ${vw('margin-bottom', 16, 20, 40)}
  }

  ul {
    ${vw('padding-left', 24)}
    ${vw('height', 384, 428)}
    ${vw('width', 230, 420, 400)}
  }

  li {
    ${vw('line-height', 21, 28)}
    ${vw('margin-bottom', 10, 16)}
  }
`;

const Video = styled.video`
  ${vw('margin-top', 0, 77)}
  ${vw('width', 256, 647, 620)}
    ${vw('height', 198, 500, 480)}
    border: 8px solid white;
  ${vw('border-width', 8, 12, 14)}
  object-fit: cover;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  filter: drop-shadow(0px 2px 14px rgba(75, 78, 82, 0.1));
`;

export default PrivateBanner;

import React from 'react';

const ArrowRight= (props) => {
  const { className, style, onClick } = props;

  return (
    <svg 
      className={className}
      onClick={onClick}
      style={style}
      {...props}
      idth="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9994 19.616L20.0154 11.6L11.9994 3.584L10.5834 5L16.1754 10.616H3.9834V12.584H16.1754L10.5834 18.2L11.9994 19.616Z" fill="#777777"/>
    </svg>
  );
};

export default ArrowRight;

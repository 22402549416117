import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Intl from 'src/components/Intl';

import vw from 'src/styles/utils';

const ScriptBanner = (props) => {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          <Script
            src={props.image}
            mobileHeight={49}
          />
        </Root>
      )}
    </Intl>
  );
};

ScriptBanner.propTypes = {
  image: PropTypes.element,
  mobileHeight: PropTypes.number
};


const Root = styled.div`
  text-align: center;
  background-color: #F2F2EC;
  ${vw('padding-top', 32, 60, 180)}
  ${vw('padding-bottom', 32, 60)}
  ${vw('margin-top', 0, 0, -120)}
`;

const Script = styled.img`
  width: auto;
  ${vw('width', 288, 602)}
  ${vw('height', 46, 111)}
`;

export default ScriptBanner;

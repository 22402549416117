import React, { useState } from 'react';
import styled from 'styled-components';

// Components
import Intl from 'src/components/Intl';
import ScriptBanner from 'src/components/ScriptBanner';
import CloseIcon from './CloseIcon';

// Assets
import ScriptImage from 'src/assets/images/neighbourhood/script.svg';
import NeighbourhoodImage from 'src/assets/images/neighbourhood/map.png';
import TransitImage from 'src/assets/images/neighbourhood/transit.svg';

import data from 'src/data/neighbourhood';
import theme from 'src/styles/theme';
import vw from 'src/styles/utils';
import media from 'src/styles/media';

const NeighbourhoodMap = () => {
  const [activeOrder, setActiveOrder] = useState(null);
  const [activeCategory, setActiveCategory] = useState(0);

  const mouseEnterHandler = (order) => {
    setActiveOrder(order);
  };

  const mouseLeaveHandler = () => {
    setActiveOrder(null);
  };

  const categoryClickHandler = (categoryClickedIndex) => {
    if (activeCategory === categoryClickedIndex) {
      setActiveCategory(undefined);
    } else {
      setActiveCategory(categoryClickedIndex);
    }
  };

  const categorySelectChangeHandler = (e) => {
    setActiveCategory(e.target.value);
  };

  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          <ScriptBanner image={ScriptImage} />
          <Wrapper>
            {/* Map: Image Banner */}
            <ImageBanner>
              <img
                src={NeighbourhoodImage}
                alt="Neighbourhood map"
                width="1000"
              />
              {activeCategory === undefined ||
                data[activeCategory].points.map((item, index) => (
                  <Item
                    key={index}
                    top={item.top}
                    left={item.left}
                    active={activeOrder === index}
                    onMouseEnter={() => mouseEnterHandler(index)}
                    onMouseLeave={mouseLeaveHandler}
                  >
                    <ItemLabel>{index + 1}</ItemLabel>
                  </Item>
                ))}
            </ImageBanner>

            {/* Legends: Text Banner */}
            <TextBanner>
              {/* Tablet and Mobile select dropdown */}
              <Form className="form">
                <label className="label">Select a Category</label>
                <Select
                  onChange={categorySelectChangeHandler}
                  value={activeCategory}
                >
                  {data.map((category, index) => (
                    <option key={index} value={index}>
                      {t.neighbourhood.map.categories[category.titleId]}
                    </option>
                  ))}
                </Select>
                <img
                  src={require('src/assets/images/icons/select-down.svg')}
                  alt="Select"
                />
              </Form>

              <TabletMobileActivePoints>
                <CategoryPoints>
                  {activeCategory === undefined
                    ? data[0].points.map((item, index) => (
                        <TextItem
                          key={index}
                          top={item.top}
                          left={item.left}
                          active={activeOrder === index}
                          onMouseEnter={() => mouseEnterHandler(index)}
                          onMouseLeave={mouseLeaveHandler}
                        >
                          <strong>{index + 1}</strong> {item.text}
                        </TextItem>
                      ))
                    : data[activeCategory].points.map((item, index) => (
                        <TextItem
                          key={index}
                          top={item.top}
                          left={item.left}
                          active={activeOrder === index}
                          onMouseEnter={() => mouseEnterHandler(index)}
                          onMouseLeave={mouseLeaveHandler}
                        >
                          <strong>{index + 1}</strong> {item.text}
                        </TextItem>
                      ))}
                </CategoryPoints>
              </TabletMobileActivePoints>
              {/* End of tablet and mobile dropdown */}

              <TextItemWrapper>
                {/* Desktop category menu */}
                {data.map((category, index) => (
                  <Category key={index}>
                    <CategoryTitle onClick={() => categoryClickHandler(index)}>
                      <span>
                        {t.neighbourhood.map.categories[category.titleId]}
                      </span>
                      <CloseIcon showClose={index === activeCategory} />
                    </CategoryTitle>
                    {index === activeCategory && (
                      <CategoryPoints>
                        {category.points.map((item, index) => (
                          <TextItem
                            key={index}
                            top={item.top}
                            left={item.left}
                            active={activeOrder === index}
                            onMouseEnter={() => mouseEnterHandler(index)}
                            onMouseLeave={mouseLeaveHandler}
                          >
                            <strong>{index + 1}</strong> {item.text}
                          </TextItem>
                        ))}
                      </CategoryPoints>
                    )}
                  </Category>
                ))}
              </TextItemWrapper>

              <Transit src={TransitImage} width="315" height="26" />
            </TextBanner>
          </Wrapper>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  position: relative;
  background-color: #f2f2ec;
  ${vw('padding-top', 31, 0, 0)}
  ${vw('padding-bottom', 35, 60, 120)}
`;

const Wrapper = styled.div`
  @media ${media.desktop} {
    display: flex;
  }
`;

const ImageBanner = styled.div`
  position: relative;
  ${vw('width', 320, 768, 886)};
  ${vw('height', 246, 580, 680)};

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const TextBanner = styled.div`
  background-color: #f2f2ec;
  ${vw('width', 288, 648, 490)}
  ${vw('padding-top', 15, 30, 0)}
  ${vw('padding-left', 0, 0, 30)}
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Form = styled.form`
  position: relative;
  @media ${media.desktop} {
    display: none;
  }
  img {
    display: block;
    position: absolute;
    ${vw('width', 12)}
    ${vw('top', 40, 44, 0)}
    ${vw('right', 12, 16, 0)}
  }
`;

const Select = styled.select`
  display: flex;
  align-items: center;
  width: 100%;
  font-family: ${theme.font.family};
  font-style: none;
  width: 100%;
  background-color: white;
  position: relative;
  appearance: none;
  border-radius: 0;
  border: 1px solid ${theme.color.cc_grey};
  color: ${theme.color.cc_grey};
  ${vw('font-size', 14, 16, 18)}
  ${vw('letter-spacing', 5, 7)}
  line-height: 24px;
  transition: ${({ theme }) => theme.transition};
  text-transform: uppercase;
  ${vw('height', 38, 40)}
  ${vw('padding-top', 9, 10)}
  ${vw('padding-right', 12)}
  ${vw('padding-left', 12)}
  ${vw('padding-bottom', 9, 10)}
  ${vw('margin-top', 8, 4)}
  ${vw('margin-bottom', 10)}
  &:focus {
    outline: none;
    background-color: white;
  }
`;

const TextItemWrapper = styled.ul`
  display: none;

  @media ${media.desktop} {
    display: unset;
  }
`;

const TabletMobileActivePoints = styled.ul`
  @media ${media.desktop} {
    display: none;
  }
`;

const TextItem = styled.li`
  color: ${theme.color.cc_grey};
  font-family: ${theme.font.sans_serif_family};
  ${vw('font-size', 11, 14)}
  ${vw('line-height', 16)}
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  align-items: baseline;
  cursor: pointer;
  ${vw('margin-bottom', 6)}
  ${vw('margin-top', 6)}

  strong {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    ${vw('width', 15, 30, 35)};
    ${vw('height', 15, 30, 35)};
    ${vw('margin-right', 8, 37, 26)}
  }

  @media ${media.tablet} {
    ${({ active }) =>
      active &&
      `
      strong {
        color: white;
        background-color: ${theme.color.cc_blue};
      }
    `}
  }
`;

const Item = styled.div`
  ${vw('width', 17.5, 26, 30)};
  ${vw('height', 17.5, 26, 30)};
  ${vw('font-size', 14)}
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  background-color: ${theme.color.cc_grey};
  border-radius: 50%;
  box-shadow: 0 5px 15px 0 ${theme.color.cc_grey};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid white;
  transition: 0.2s all ease;

  @media ${media.tablet} {
    &:hover {
      background-color: ${theme.color.cc_blue};
      -webkit-transform: scale(1.15);
      -ms-transform: scale(1.15);
      transform: scale(1.15);
    }

    ${({ active }) =>
      active &&
      `
        background-color: ${theme.color.cc_blue};
        -webkit-transform: scale(1.15);
        -ms-transform: scale(1.15);
        transform: scale(1.15);
    `}
  }
`;

const ItemLabel = styled.span`
  color: white;
  ${vw('font-size', 14)};
  font-family: ${theme.font.sans_serif_family};
  font-weight: 600;
`;

const Category = styled.div`
  transition: 0.2s all ease;
`;

const CategoryPoints = styled.div`
  border-bottom: 1.5px dashed ${theme.color.cc_grey};
  ${vw('padding-bottom', 10)};
`;

const CategoryTitle = styled.h3`
  ${vw('font-size', 18)}
  ${vw('letter-spacing', 7)}
  ${vw('line-height', 24)}
  ${vw('padding-top', 11)}
  ${vw('padding-bottom', 11)}
  ${vw('padding-right', 17)}
  ${vw('margin-bottom', 0)}
  text-transform: uppercase;
  border-bottom: 1.5px dashed ${theme.color.cc_grey};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Transit = styled.img`
  ${vw('width', 248, 315)}
  ${vw('height', 20, 26)}

  ${vw('margin-top', 18, 23, 0)}
`;

export default NeighbourhoodMap;

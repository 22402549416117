import React from 'react';
import styled from 'styled-components';
import { Parallax } from 'react-scroll-parallax';

import Intl from 'src/components/Intl';
import Tagline from 'src/assets/images/elements/script_tagline.svg';
import TreeRings from 'src/assets/images/elements/tree_rings_1.svg';

import GhostButton from 'src/components/GhostButton';

import theme from 'src/styles/theme';
import vw from 'src/styles/utils';

const Banner = () => (
  <Intl>
    {(t) => (
      <Root>
        <StyledTagline src={Tagline} />
        <p className="body">{t.home.banner1.description}</p>
        <GhostButton to="/floorplans" text={t.home.banner1.buttonLabel} />

        <Parallax className="parallax" y={[-40, 20]} tagOuter="figure">
          <Watermark src={TreeRings} />
        </Parallax>
      </Root>
    )}
  </Intl>
);

const Root = styled.div`
  position: relative;
  z-index: 1;
  background-color: ${theme.color.cc_off_white};
  ${vw('margin-top', -80, -80, -120)}
  ${vw('padding-top', 168, 189, 284)}
  ${vw('padding-left', 32, 60, 290)}
  ${vw('padding-right', 32, 60, 0)}
  ${vw('padding-bottom', 32, 60, 80)}

  p {
    ${vw('width', 257, 647, 1030)}
    ${vw('margin-top', 16, 8, 20)}
    ${vw('font-size', 13, 14, 16)}
    ${vw('line-height', 26, 28, 32)}
    ${vw('letter-spacing', 1, 1, 3)}
  }

  .parallax {
    position: absolute;
    ${vw('left', -65, -85, -113)}
    ${vw('top', -58, -100, 376)}
  }
`;

const Watermark = styled.img`
  opacity: 0.5;

  ${vw('width', 180, 241, 320)}
  ${vw('height', 194, 260, 345)}
`;

const StyledTagline = styled.img`
  ${vw('width', 257, 480, 620)}
  ${vw('height', 38, 71, 92)}
`;

export default Banner;

import React from 'react';

const ArrowLeft = (props) => {
  const { className, onClick } = props;

  return (
    <svg
      className={className}
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0006 19.616L3.9846 11.6L12.0006 3.584L13.4166 5L7.8246 10.616H20.0166V12.584H7.8246L13.4166 18.2L12.0006 19.616Z"
        fill="#777777"
      />
    </svg>
  );
};

export default ArrowLeft;

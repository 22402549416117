export default {
  color: {
    cc_blue: '#00AEC7',
    cc_blue_dark: '#00869F',
    cc_grey: '#4B4E52',
    cc_off_white_bg: '#F8F8F6',
    cc_off_white: '#F4F4F0'
  },
  font: {
    family: 'Anziano',
    sans_serif_family: 'Work Sans'
  },
  transition: '0.4s ease',
};

import React from 'react';
import styled from 'styled-components';
import Intl from 'src/components/Intl';
import GhostButton from 'src/components/GhostButton';

import vw from 'src/styles/utils';
import theme from 'src/styles/theme';

const PageContent = (props) => {
  return (
    <Intl>
      {(t, { getLocaleURL }) => {
        return (
          <Root>
            {
              props.title &&
              <h1>{props.title}</h1>
            }
            {
              props.subtitle &&
              <h4>{props.subtitle}</h4>
            }

            {
              props.body &&
              <p className="body">
                {
                  props.body
                }
              </p>
            }

            {
              props.homeButton && 
              <GhostButton to={`${getLocaleURL()}/`} text={t.home.backHome}></GhostButton>
            }

            {
              props.children
            }
          </Root>
        );
      }}
    </Intl>
  );
};

const Root = styled.div`
  position: relative;
  background-color: ${theme.color.cc_off_white_bg};
  text-align: center;
  ${vw('padding-top', 100, 160, 240)}
  ${vw('padding-bottom', 120)}
  z-index: 0;

  h4 {
    ${vw('margin-top', 40)}
  }

  p {
    ${vw('max-width', 602)}
    ${vw('margin-top', 40)}
    ${vw('margin-bottom', 80)}
    margin-left: auto;
    margin-right: auto;
  }
`;

export default PageContent;
import React from 'react';

import Page from 'src/components/Page';
import Intl from 'src/components/Intl';

const NotFound = () => {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Page
          title={t.notFound.title}
          subtitle={t.notFound.subtitle}
          body={t.notFound.body}
          homeButton={true}
        >
        </Page>
      )}
    </Intl>
  );
};

export default NotFound;

import React from 'react';
import styled from 'styled-components';

import theme from 'src/styles/theme';

const CloseIcon = (props) => {
  const {showClose} = props;

  return (
    <Root showClose={showClose} width="9" height="9" viewBox="0 0 9 9" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.423928" d="M4.92552 8.36692V5.10288H8.42175V3.97046H4.92552V0.706421H3.71254V3.97046H0.216309V5.10288H3.71254V8.36692H4.92552Z" fill="#4B4E52"/>
    </Root>
  );
};

const Root = styled.svg`
  stroke: ${(props) => props.showClose ? theme.color.cc_blue : theme.color.cc_grey};
  transform: ${(props) => props.showClose ? 'rotate(45deg)' : 'rotate(0)'};
  transition: 0.2s all ease;
`;

export default CloseIcon;

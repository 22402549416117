import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Intl from 'src/components/Intl';
import media from 'src/styles/media';
import theme from 'src/styles/theme';
import vw from 'src/styles/utils';

const LanguageSwitcher = (props) => {
  return (
    <Intl>
      {(t, { locale }) => (
        <Root {...props}>
          <StyledLink to="/" active={locale === 'en'} className="en">
            EN
          </StyledLink>
          <StyledLink to="/tc" active={locale === 'tc'}>
            中文
          </StyledLink>
          <StyledLink to="/ko" active={locale === 'ko'}>
            한국어
          </StyledLink>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`

  ${vw('width', 136)}
  margin: auto;
  ${vw('margin-top', 16, 40, 0)}
  justify-content: space-between;
  align-items: center;
  display: flex;

  &.show-on-desktop {
    display: none;
  }

  @media ${media.desktop} {
    &.show-on-desktop {
      align-items: baseline;
      grid-column: 3 / 4;
      grid-row: 1 / 2;
      display: flex;
      margin-left: auto;
      margin-right: 0;
    }

    &.show-on-tablet {
      display: none;
    }
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  font-weight: 500;
  color: ${(props) => props.active ? theme.color.cc_blue : theme.color.cc_grey};
  font-family: ${theme.font.sans_serif_family};
  ${vw('font-size', 13)}
  ${vw('letter-spacing', 4)}

  &.en {
    ${vw('letter-spacing', 2)}
  }

  ${(props) => props.active && 
    `&:after {
      content: '';
      display: block;
      position: absolute;
      background-color: ${theme.color.cc_blue};
      width: 93%;
      height: 2px;
      bottom: -4px;
    }`
  }
`;

export default LanguageSwitcher;
import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import Form from './Form';
import Page from 'src/components/Page';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Register = () => {
  return (
    <Intl>
      {(t) => (
        <Page title={t.register.title}>
          <Root id="register">
            <Form />
          </Root>
        </Page>
      )}
    </Intl>
  );
};

const Root = styled.main`
  padding: ${vwMobile(60)} ${vwMobile(16)};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  background-position: center;
  @media ${media.tablet} {
    text-align: left;
    padding: ${vwTablet(80)} ${vwTablet(60)};
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(100)} ${vwDesktop(280)} ${vwDesktop(120)};
  }
`;

export default Register;

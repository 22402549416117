import React from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

// Icons
import ArrowLeft from 'src/components/icons/ArrowLeft';
import ArrowRight from 'src/components/icons/ArrowRight';

// Styles
import vw from 'src/styles/utils';
import theme from 'src/styles/theme';
import media from 'src/styles/media';

// Slick styles
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Slick = (props) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: true,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Root>
      <Title>{props.title}</Title>
      <Slider {...settings}>
        {props.images.map((image, index) => (
          <Card key={index}>
            <CardContent>
              <CardImage className="card-image" bg={image.image}></CardImage>
            </CardContent>

            <p className="caption">{image.text}</p>
          </Card>
        ))}
      </Slider>
    </Root>
  );
};

const Root = styled.div`
  background-color: ${theme.color.cc_off_white_bg};
  ${vw('padding-left', 32, 60, 100)}
  ${vw('padding-right', 32, 60, 100)}
  ${vw('padding-top', 32, 30, 80)}
  ${vw('padding-bottom', 62, 120)}

  .slick-slider {
    ${vw('margin-left', 0, -15)}
    ${vw('margin-right', 0, -15)}
  }
`;

const Title = styled.h3`
  font-weight: 300;
  text-transform: uppercase;
  color: ${theme.color.cc_grey};
  ${vw('margin-bottom', 16, 30)}
  ${vw('font-size', 18)}
  ${vw('line-height', 24)}
  ${vw('letter-spacing', 7)}
  text-align: center;

  @media ${media.tablet} {
    text-align: left;
  }
`;

const Card = styled.div`
  ${vw('padding-left', 0, 10)}
  ${vw('padding-right', 0, 10)}
  text-align: center;
`;

const CardContent = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  ${vw('height', 184, 205, 420)}
  ${vw('margin-bottom', 8, 12)}
  filter: drop-shadow(0px 3px 4px rgba(75, 78, 82, 0.4008));
`;

// const Svg = styled.svg`
//   position: absolute;
//   top: 50%;
//   left: 0;
//   right: 0;
//   margin: auto;
//   transform: translateY(-25%);
//   ${vw('width', 30, 30, 40)}
//   ${vw('height', 30, 30, 40)}

//   circle {
//     transition: all 0.8s ease 0s;
//   }
// `;

const CardImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.bg});
  background-position: center;
  background-size: cover;
  transition: all 0.8s ease 0s;
`;

export default Slick;

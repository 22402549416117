export default [
  {
    id: 'integra',
    name: 'INTEGRA ARCHITECTURE INC.',
    logo: 'logo_integra.svg',
    logoHeightDesktop: 75,
    logoHeightMobile: 55,
    link: 'https://integra-arch.com/',
  },
  {
    id: 'portico',
    name: 'Portico Design Group',
    logo: 'logo_portico.svg',
    logoHeightDesktop: 75,
    logoHeightMobile: 50,
    link: 'https://www.porticodesign.com/',
  },
  {
    id: 'prospect',
    name: 'Prospect and Refuge',
    logo: 'logo_prospect.png',
    logoHeightDesktop: 65,
    logoHeightMobile: 50,
    link: 'https://prospectrefuge.ca/',
  },
];

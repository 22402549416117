import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';

function PhotoLandscape(props) {
  const { src } = props;
  return (
    <Root src={src}></Root>
  );
};

const Root = styled.img`
  ${vw('width', 256, 647, 620)}
  ${vw('height', 198, 500, 480)}
  border: 8px solid white;
  ${vw('border-width', 8, 12, 14)}
  object-fit: cover;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  filter: drop-shadow(0px 2px 14px rgba(75, 78, 82, 0.10));
`;

export default PhotoLandscape;

import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import GhostButton from 'src/components/GhostButton';
import PhotoLandscape from 'src/components/PhotoLandscape';
import NatureRendering from 'src/assets/images/home/cyclists.png';
import Trees from 'src/assets/images/elements/cc_trees.svg';
import vw from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

function NatureBanner() {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          <PhotoLandscape src={NatureRendering} />
          <Article>
            <h1>{t.home.banner4.title}</h1>
            <p className="body">
              {t.home.banner4.description}
            </p>
            <GhostButton  to={`${getLocaleURL()}/neighbourhood`} text={t.home.banner4.buttonLabel} />
          </Article>
          <Watermark src={Trees} />
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  background-color: ${theme.color.cc_off_white};
  ${vw('padding-top', 0)}
  ${vw('padding-bottom', 64, 120, 130)}
  ${vw('padding-left', 32, 60, 207)}
  ${vw('padding-right', 32, 60, 101)}
  position: relative;
  
  @media ${media.desktop} {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const Article = styled.div`
  position: relative;
  z-index: 1;
  ${vw('width', 256, 534, 440)}
  ${vw('margin-top', 16, 30, 150)}

  p {
    margin-right: 0;
    ${vw('margin-bottom', 40)}
  }
`;

const Watermark = styled.img`
  opacity: 0.6;
  position: absolute;
  z-index: 0;
  ${vw('top', 117, 388, -112)}
  ${vw('left', -95, -47, -60)}
  ${vw('width', 200, 300, 400)}
  ${vw('height', 238, 358, 475)}
`;

export default NatureBanner;

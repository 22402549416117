import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import vw, { vwMobile } from 'src/styles/utils';

import HeroImage from 'src/assets/images/gallery/hero.jpg';

const Hero = () => {
  return (
    <Intl>
      {(t) => (
        <Root>
          <ImageWrapper>
            <h1>{t.gallery.hero.title}</h1>
            <Image src={HeroImage} />
            <p className="caption">{t.gallery.hero.caption}</p>
          </ImageWrapper>

          <TextWrapper>
            <p className="body">{t.gallery.hero.body}</p>
          </TextWrapper>
        </Root>
      )}
    </Intl>
  );
};

export default Hero;

const Root = styled.div`
  ${vw('padding-top', 52, 88, 148)}
  ${vw('padding-bottom', 64, 120, 111)}
  background-color: #F8F8F6;

  .caption {
    width: ${vwMobile(228)};
  }

  h1 {
    text-align: center;
    ${vw('margin-bottom', 16, 30, 58)}
  }
`;

const ImageWrapper = styled.div`
  ${vw('padding-left', 16, 24, 48)}
  ${vw('padding-right', 16, 24, 48)}
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const TextWrapper = styled.div`
  ${vw('padding-top', 64, 120, 92)}
  ${vw('padding-left', 32, 175, 737)}
  ${vw('padding-right', 32, 62, 101)}

  p {
    margin: 0;
  }
`;

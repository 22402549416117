import React, { useState } from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import Accordion from './Accordion';
import data from 'src/data/floorplans';
import vw from 'src/styles/utils';
import theme from 'src/styles/theme';

const unitsByPhase = {
  1: [],
  // 1: ['a', 'a2', 'e1'],
  2: ['2_a'],
};

const FloorplansWrapper = (props) => {
  const unitsShowing = unitsByPhase[props.phase];

  const getDefaultUnits = () => {
    const defaultUnits = {};
    unitsShowing.forEach((unit) => {
      defaultUnits[unit] = false;
    });
    return defaultUnits;
  };

  const [unitsState, setUnitsState] = useState(getDefaultUnits());

  const toggleExpanded = (unitId) => {
    // Get the current unit expanded state
    const currentUnitExpanded = unitsState[unitId];

    // Reset
    const newState = {
      ...getDefaultUnits(),
    };

    // Assign the toggled current unit state to the new unit state
    newState[unitId] = !currentUnitExpanded;

    // Set state with new state
    setUnitsState(newState);
  };

  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          {unitsShowing.map((unitId) => (
            <Accordion
              unitId={unitId}
              display={data[unitId].display}
              type={t.floorplans.types[data[unitId].typeId]}
              sqft={data[unitId].indoor}
              unitPng={require(`../../assets/images/floorplans/${unitId}.png`)}
              plateThumb={require(`../../assets/images/floorplans/plateThumbs/${unitId}.png`)}
              pdfLink={`/floorplans/${unitId}.pdf`}
              expanded={unitsState[unitId]}
              onClick={toggleExpanded}
            />
          ))}

          <Disclaimer>
            In a continuing effort to meet the challenge of product
            improvements, we reserve the right to modify or change dimensions,
            sizes, specifications, plan orientations and materials without
            notice. All patio, terrace and balcony sizes may vary. Windows,
            columns, privacy screens, demising walls and guardrail details may
            vary depending upon the plan and floor level. The quality homes at
            Cedar Creek 2 are built by LM Cedar Creek Homes Limited Partnership.
            E & O.E.
          </Disclaimer>
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  border-top: 1px solid #4b4e52;
  ${vw('border-width', 1, 3)}
  ${vw('margin-left', 12, 24, 0)}
  ${vw('margin-right', 12, 24, 0)}
  ${vw('margin-bottom', 32, 60, 120)}
`;

const Disclaimer = styled.p`
  color: ${theme.color.cc_grey};
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  ${vw('font-size', 9, 10)}
  ${vw('line-height', 13, 16)}
  ${vw('padding-top', 16)}
  line-height: 13px;
  /* or 144% */

  text-align: justify;
`;

export default FloorplansWrapper;

import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';
import PhotoLandscape from 'src/components/PhotoLandscape';
import ExpandRendering from 'src/assets/images/interiors/kitchen.jpg';
import TreeRingB from 'src/assets/images/elements/tree_rings_2.svg';

import vw from 'src/styles/utils';
import media from 'src/styles/media';
import theme from 'src/styles/theme';

function HeartBanner() {
  return (
    <Intl>
      {(t, { getLocaleURL, locale }) => (
        <Root>
          <PhotoLandscape src={ExpandRendering} />
          <Article>
            <h1>{t.interiors.banner1.title}</h1>
            <p className="body">{t.interiors.banner1.body}</p>
          </Article>
          <Watermark src={TreeRingB} />
        </Root>
      )}
    </Intl>
  );
};

const Root = styled.div`
  background-color: ${theme.color.cc_off_white_bg};
  ${vw('margin-top', 0, 0, -130)}
  ${vw('padding-top', 0, 0, 240)}
  ${vw('padding-bottom', 64, 120, 130)}
  ${vw('padding-left', 32, 60, 122)}
  ${vw('padding-right', 32, 60, 170)}

  @media ${media.tablet} {
    background-color: ${theme.color.cc_off_white};
  }

  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
  }
  position: relative;
`;

const Article = styled.div`
  ${vw('width', 256, 534, 400)}
  ${vw('margin-top', 16, 30, 130)}

  p {
    margin-right: 0;
    ${vw('margin-bottom', 40)}
  }
`;

const Watermark = styled.img`
  opacity: 0.2;
  position: absolute;
  ${vw('right', -90)}
  ${vw('bottom', -260)}
  ${vw('width', 370)}
  ${vw('height', 370)}
  z-index: 1;

  display: none;

  @media ${media.desktop} {
    display: unset;
  }
`

export default HeartBanner;

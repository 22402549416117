export default {
  interiors: [
    {
      caption: 'Kitchen',
      image: require('src/assets/images/gallery/interiors/interiors-kitchen.jpg'),
    },
    {
      caption: 'Living',
      image: require('src/assets/images/gallery/interiors/interiors-living.jpg'),
    },
    {
      caption: 'Dining',
      image: require('src/assets/images/gallery/interiors/interiors-dining.jpg'),
    },
    {
      caption: 'Bathroom',
      image: require('src/assets/images/gallery/interiors/interiors-bathroom.jpg'),
    },
    {
      caption: 'Patio',
      image: require('src/assets/images/gallery/interiors/interiors-patio.jpg'),
    },
    {
      caption: 'Bedroom',
      image: require('src/assets/images/gallery/interiors/interiors-bedroom.jpg'),
    },
    // {
    //   caption: 'Kitchen',
    //   image: require('src/assets/images/gallery/interiors/6.jpg'),
    // },
    // {
    //   caption: 'Living',
    //   image: require('src/assets/images/gallery/interiors/7.jpg'),
    // },
    // {
    //   caption: 'Dining',
    //   image: require('src/assets/images/gallery/interiors/8.jpg'),
    // },
    // {
    //   caption: 'Bathroom',
    //   image: require('src/assets/images/gallery/interiors/10.jpg'),
    // },
  ],
  neighbourhood: [
    {
      caption: 'The Signature Collection - Phase 1',
      image: require('src/assets/images/gallery/neighbourhood/neighbourhood-phase-1.jpg'),
    },
    {
      caption: 'The Signature Collection - Phase 2',
      image: require('src/assets/images/gallery/neighbourhood/neighbourhood-phase-2.jpg'),
    },
    {
      caption: 'Local Produce Store',
      image: require('src/assets/images/gallery/neighbourhood/3.jpg'),
    },
    {
      caption: 'Upscale Sushi Restaurant',
      image: require('src/assets/images/gallery/neighbourhood/4.jpg'),
    },
    {
      caption: 'Gourmet Grocery Store',
      image: require('src/assets/images/gallery/neighbourhood/5.jpg'),
    },
    {
      caption: 'State of art fitness centre',
      image: require('src/assets/images/gallery/neighbourhood/6.jpg'),
    },
    {
      caption: 'Save-on-foods',
      image: require('src/assets/images/gallery/neighbourhood/7.jpg'),
    },
    {
      caption: 'Skytrain',
      image: require('src/assets/images/gallery/neighbourhood/8.jpg'),
    },
    {
      caption: 'Deer Lake Park',
      image: require('src/assets/images/gallery/neighbourhood/9.jpg'),
    },
    {
      caption: 'Rocky Point',
      image: require('src/assets/images/gallery/neighbourhood/10.jpg'),
    },
  ],
};

import React from 'react';

export default {
  pages: {
    floorplans: '户型图',
    interiors: '室內設計',
    features: '特色',
    neighbourhood: '社區',
    gallery: '圖庫',
    developer: '發展商',
    register: '登記',
  },
  bookAppt: '即刻預約',
  contact: '聯繫我們',
  home: {
    nowSelling: '現正熱銷，僅剩四套房屋。',
    banner1: {
      title: '典藏卓越系列',
      description:
        '正如同 Cedar Creek 社區內所有精心規劃的精品府邸一般，作為收官之作的 Signature Collection，同樣講究清新及現代化的典雅設計風格。這一系列的精品住宅，包含 1 房 + 書房至 3 房等多元戶型，適合各種不同的生活型態，不論您的家庭成員人數多寡，都能在此找到最舒適合宜的生活空間。',
      buttonLabel: '瀏覽戶型圖',
    },
    banner2: {
      title: '靜謐自然 歡迎回家',
      description: (
        <>
          以雄偉高大的卑詩雪松樹為靈感來源，Ledingham McAllister 精心打造
          Signature Collection
          系列精品住宅，體現獨特的西岸現代化風格，讓您在本拿比Edmonds
          的黃金地段，坐享便捷的完美生活。
          <br />
          <br />
          Cedar Creek
          的木造溫馨府邸，以挑高天花及明亮開放式的設計，以及寬敞的戶外空間，打造無與倫比的居家環境。
        </>
      ),
      buttonLabel: '瀏覽室內設計',
    },
    banner3: {
      title: '放眼戶外生活',
      description:
        'Cedar Creek 的住戶，獨享許多超凡戶外及室內設施，不論是在健身中心鍛鍊體魄、與三五好友共度悠閒時光，或款待遠道而來、需要借宿幾夜的親友，您都無需舟車勞頓，便能充分享受生活。',
      buttonLabel: '探索特色',
    },
    banner4: {
      title: '本拿比的優渥環境',
      description:
        'Cedar Creek 的 Signature Collection 系列精品住宅，與本拿比遼闊的步道網絡、公園、湖泊與森林為鄰，您可透過散步、騎車，甚至是划船的方式，來親身體驗大自然的一切美好。',
      buttonLabel: '社區介紹',
    },
    backHome: 'back to homepage',
  },
  floorplans: {
    header: {
      plan: '戶型',
      type: '類型',
      sqft: '大約平方英尺',
    },
    welcome: {
      title: '歡迎回家',
      body: '1 房 + 書房至 3 房的多元戶型，結合典雅的設計風格及實用性，讓您對以此為家，總是感到無比驕傲！',
    },
    types: {
      oneBedOneDen: '1 房 & 書房',
      twoBed: '2 房',
      threeBed: '3 房',
    },
    downloadPlan: '下載戶型圖',
  },
  interiors: {
    hero: {
      title: (
        <>
          您專屬的
          <br />
          溫馨天地
        </>
      ),
      body: '以超高天花板，能迎入自然光的特大窗戶，以及開放式的起居空間，塑造最適合您的生活環境。',
    },
    banner1: {
      title: <>家庭之心</>,
      body: '出色的聚會，總與美食息息相關。這裡的廚房，是專為招呼賓客所設計。不論是準備開胃小食或是主菜，您都能在此大顯廚藝。社區內大多數的單位，也設置了食品儲藏空間，讓您無須擔憂食材的收納位置不足。廚房內的中島及早餐桌，還可方便您備餐，以及輕鬆享受美食。這些廚房，必能讓您毫無後顧之憂地為親友創造難忘回憶！',
    },
    banner2: {
      title: '引以為傲',
      body: (
        <>
          <li>獲獎無數的 Integra Architecture 操刀設計的現代化西岸住宅</li>
          <li>由 Portico Design 精心打造的兩款室內裝修主題色調任君挑選</li>
          <li>迎入清新空氣與充足自然光線的 9’ 挑高天花</li>
          <li>綠意盎然、無所不在的自然造景及社區花園</li>
          <li>致力於為您提供無憂居家生活的專屬客服團隊</li>
        </>
      ),
      buttonLabel: '下載特色介紹',
    },
  },
  features: {
    hero: {
      title: '放眼戶外生活',
      body: 'Cedar Creek 的住戶，獨享許多超凡戶外及室內設施，不論是在健身中心鍛鍊體魄、與三五好友共度悠閒時光，或款待遠道而來、需要借宿幾夜的親友，您都無需舟車勞頓，便能充分享受生活。',
    },
    banner1: {
      title: (
        <>
          四季皆宜的
          <br />
          寬敞戶外空間
        </>
      ),
    },
    banner2: {
      body: '社區內的休閒設施將大自然與您的生活完善結合。當您想要舒緩身心時，無論是專屬的私人陽台，或是社區內的戶外休閒空間，皆是能盡享暖陽及新鮮空氣的好去處',
      buttonLabel: '下載特色介紹',
    },
    landscape: {
      title: '造景計畫',
      labels: {
        entrance: '入口',
        bikeRacks: '單車架',
        parkadeEntrance: '停車場入口',
        childrensPlayArea: '兒童遊戲區',
        picnicTables: '野餐區',
        gardenPlots: '社區花園',
      },
    },
  },
  neighbourhood: {
    hero: {
      title: (
        <>
          本拿比的
          <br />
          優渥環境
        </>
      ),
      body: 'Cedar Creek 與本拿比遼闊的步道網絡、公園、湖泊與森林為鄰，讓您能輕鬆地以不同的方式，來體驗大自然的各種美好。',
    },
    banner1: {
      title: '在本拿比 EDMONDS 的黃金地段 享受便利生活',
    },
    banner2: {
      body: 'Cedar Creek 地處本拿比知名的 Edmonds 住宅區，佔盡地利之便，許多熱門精品商店、現代化娛樂場所及公交樞紐皆徒步可達。這裡不僅僅是一個嶄新社區，更融合了大都會的各種康娛設施，為您呈現極致的便利生活！',
    },
    map: {
      categories: {
        parks: '公園及休閒去處',
        restaurants: '餐廳及咖啡廳',
        education: '學校及教育機構',
        shopping: '購物',
        fitness: '健身',
        transit: '公交',
      },
    },
  },
  gallery: {
    hero: {
      title: '設計靈感源於西岸',
      caption: 'Cedar Creek Signature Collection Phase 2',
      body: '從綠意盎然的花園景觀設計，到以磚牆及木材點墜的宏偉半開放式入口通道，以至於通道盡頭那設置了典雅爐畔座位、且擁有挑高天花空間的迎賓大堂，Cedar Creek 期盼以對建築細節的講究，以及面面俱到的貼心設計，讓您在這裡找到家的歸屬感！',
    },
    banner1: {
      title: 'Interiors',
      captions: {
        kitchen: 'kitchen',
        dining: 'dining',
        living: 'living',
        office: 'office',
        bedroom: 'bedroom',
        bathroom: 'bathroom',
      },
    },
    banner2: {
      title: 'Neighbourhood',
    },
  },
  developer: {
    hero: {
      title: '信心。卓越。超值',
      body: '在Ledingham McAllister 我們明智的選擇在優越的地理位置創建卓越的新家庭社區。一個多世紀以來，我們一直致力於執行嚴格的設計標準、環境領導、工程和施工標準，以確保住宅具有長久的質量保障和價值。建造了18,000個家園之後，我們可以自豪地說，我們的過去是建立在這些住宅的基礎之上，而我們的未來也以將此為基礎。',
    },
    companies: {
      integra: {
        department: '建築設計',
        description: (
          <>
            獲得許多獎項肯定的 Integra Architecture INC.
            ，是一間位在溫哥華市中心的建築公司。他們才華洋溢且追求卓越的專業團隊，特別著重客戶關係、有效溝通，以及高質量的永續設計。他們的經驗豐富，曾參與包含住宅大樓，混用項目及長者住宅等各類不同項目的建築設計。
            <br />
            <br />
            透過完善溝通及優質的建築設計繪圖，他們能在了解客戶需求的前提下，實現傑出的建築設計願景。
          </>
        ),
      },
      portico: {
        department: '室內設計',
        description: (
          <>
            以「賦予空間生命」為主要的理念，Portico Design
            所打造的生動空間，不僅令人耳目一新，更令人們能與每天所在的生活環境合而為一。他們的設計，能充分反映出建築、景觀、地理位置及有關人士對項目的願景。此外，這個團隊也特別喜愛挑戰既定印象。
            <br />
            <br />
            Portico Design 創立於 1992
            年，他們的客戶包含商店東主及住宅業主，酒店業者，以及商業土地開發商。
          </>
        ),
      },
      prospect: {
        department: '景觀設計',
        description:
          '位於卑詩省溫哥華的 Prospect And Refuge，是一間提供完整服務的景觀設計公司。他們的經驗豐富，曾參項目包括多單位住宅、學校、幼兒園、醫院，以及工業區，並擅長與客戶密切合作，打造能凝聚社區，且令人留下深刻印性的卓越成果。Prospect And Refuge 以 Jonathan Losee Ltd. 公司 25 年的歷史為基礎，延續創辦人 Jon 幽默、以客為尊、著重環境，以及講究技術細節的經營風格，與客戶一起共創佳績。',
      },
    },
  },
  footer: {
    column1: {
      title: '聯繫我們',
      buttonLabel: '立即登記',
    },
    column2: {
      title: '展銷中心',
      text: (
        <>
          致電預約
          <br />
          7166 14th Ave，本拿比，卑诗省
        </>
      ),
      buttonLabel: '即刻預約',
    },
    column3: {
      title: 'Cedar Creek地址',
    },
    marketing: 'marketing in association with',
    disclaimer:
      'The information contained in this promotional material is for illustrative purposes only and is subject to change without notice. Maps and renderings are artistic representation only. The quality residences at Cedar Creek 2 are developed by LM Cedar Creek Homes Limited Partnership. The developer reserves the right to make modifications or substitutions should they be necessary. This is currently not an offering for sale. Any such offering can only be made by way of a disclosure statement. E. & O.E.',
    corporate: {
      title: '信心。卓越。超值',
      text: 'Ledingham McAllister 致力於在黃金地段打造優質住宅，持續為客戶開創長期價值。我們秉持最高標準，在過去建立卓越口碑，並期盼開創精彩未來。',
      privacy: '私隱政策',
    },
  },
  register: {
    title: '請即登記',
    firstname: '名字*',
    lastname: '姓氏*',
    email: '電郵*',
    phone: '電話*',
    zip: '郵編*',
    select: '請選擇',
    howHeard: '您如何得知關於 Cedar Creek 的訊息？*',
    howHeardOptions: [
      {
        label: '網路廣告',
        value: 'Online Ads',
      },
      {
        label: '中文廣告',
        value: 'Chinese Ads',
      },
      {
        label: '韓文廣告',
        value: 'Korean Ads',
      },
      {
        label: '印刷廣告',
        value: 'Print Advertising',
      },
      {
        label: '社交網站',
        value: 'Social Media',
      },
      {
        label: '廣告招牌',
        value: 'Signage',
      },
      {
        label: '地產經紀',
        value: 'Realtor',
      },
      {
        label: '親友告知',
        value: 'Friend & Family',
      },
      {
        label: '其他',
        value: 'Other',
      },
    ],
    whatInquire: '您心目中的理想房型為何？',
    whatInquireOptions: [
      {
        label: '1 房',
        value: 'One bedroom',
      },
      {
        label: '2 房',
        value: 'Two bedroom',
      },
      {
        label: '3 房',
        value: 'Three bedroom',
      },
      {
        label: '頂樓',
        value: 'Penthouses',
      },
    ],
    realtor: '您是否為地產經紀？*',
    workingWithRealtor: '您是否有與地產經紀合作？',
    yes: '是',
    no: '否',
    ifYesRealtor: `若您是地產經紀，或已有地產經紀代表，請提供地產經紀姓名及所屬的地產公司。`,
    disclaimer:
      '我願意收到來自 Ledingham McAllister 以電子通訊方式提供有關最新項目、搶先體驗及優惠活動等相關訊息。*',
    submit: '提交',
    required: '*必填欄位',
  },
  thankYou: {
    title: 'Thank You',
    body: 'Thank you for registering for The Signature Collection by Ledingham McAllister. Your submission has been received and a representative will be in touch with you soon.',
  },
  notFound: {
    title: (
      <>
        Sorry! <br />
        We apologize
      </>
    ),
    subtitle: '404 Not Found',
    body: (
      <>
        Looks like something went wrong here. <br />
        The page you are looking for does not exist.
      </>
    ),
  },
  privacy: {
    title: '私隱政策',
  },
};

import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Intl from 'components/Intl';

import vw from 'src/styles/utils';
import theme from 'src/styles/theme';

const Select = (props) => {
  const { label, name, id, required, options } = props;

  const [value, setValue] = useState('');

  return (
    <Intl>
      {(t) => (
        <Root>
          <label htmlFor={id} className="form label">
            {label}
          </label>
          <select
            name={name}
            id={id}
            required={required}
            className={`form input ${value === '' && 'inactive'}`}
            placeholder="Select One"
            onChange={(e) => setValue(e.target.value)}
          >
            <option value="">{t.register.select}</option>
            {options.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <div>
            <img
              src={require('src/assets/images/icons/select-down.svg')}
              alt="Select"
            />
          </div>
        </Root>
      )}
    </Intl>
  );
};

Select.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.array,
};

const Root = styled.div`
  width: 100%;
  position: relative;
  ${vw('margin-bottom', 24)}
  label {
    display: block;
    ${vw('margin-bottom', 4)}
  }
  select {
    border: 2px solid ${theme.color.cc_grey};;
    color: ${theme.color.cc_grey};
    font-family: ${theme.font.sans_serif_family};
    font-style: none;
    width: 100%;
    background-color: white;
    position: relative;
    appearance: none;
    border-radius: 0;
    border: 2px solid transparent;
    transition: ${({ theme }) => theme.transition};
    ${vw('height', 38, 40)}
    ${vw('padding-top', 9, 10)}
    ${vw('padding-right', 12)}
    ${vw('padding-left', 12)}
    ${vw('padding-bottom', 9, 10)}
    &:focus,
    &:hover {
      outline: none;
      background-color: white;
      border: 2px solid ${theme.color.cc_blue};
    }
  }
  div {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    background-color: ${({ theme }) => theme.color.blue};
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    ${vw('width', 38, 40)}
    ${vw('height', 38, 40)}
    img {
      display: block;
      ${vw('width', 12)}
    }
  }
`;

export default Select;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const Toggle = (props) => {
  const { onText, offText, currentPhase, clickHandler } = props;
  return (
    <Root>
      {/* <ToggleItem
        onClick={() => clickHandler(1)}
        active={currentPhase === 1}
      >
        {onText}
      </ToggleItem> */}

      <ToggleItem onClick={() => clickHandler(2)} active={currentPhase === 2}>
        {offText}
      </ToggleItem>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  z-index: 2;
  ${vw('margin-bottom', 55)}
  max-width: ${vwMobile(288)};
  margin-left: auto;
  margin-right: auto;

  @media ${media.tablet} {
    max-width: ${vwTablet(720)};
  }

  @media ${media.desktop} {
    max-width: unset;
    margin-left: 0;
    margin-right: 0;
  }
`;

const ToggleItem = styled.a`
  cursor: pointer;
  color: ${(props) => (props.active ? '#F4F4F0' : props.theme.color.cc_grey)};
  background-color: ${(props) =>
    props.active ? props.theme.color.cc_grey : 'white'};
  width: 100%;
  display: inline-block;
  text-align: center;
  font-family: ${(props) => props.theme.font.sans_serif_family};
  border: 1px solid ${(props) => props.theme.color.cc_grey};
  font-style: normal;
  font-weight: 700;
  ${vw('font-size', 13)}
  ${vw('line-height', 20)}
  ${vw('padding-top', 11)}
  ${vw('padding-bottom', 11)}
  ${vw('letter-spacing', 1.5, 2.2, 3)}
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  box-shadow: 0px 3px 9px rgba(75, 78, 82, 0.3);

  ${(props) =>
    props.active &&
    `
    &:after {
      content:'';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -15px;
      width: 0;
      height: 0;
      border-top: solid 15px ${props.theme.color.cc_grey};
      border-left: solid 15px transparent;
      border-right: solid 15px transparent;
    }
  `}
`;

Toggle.propTypes = {
  onText: PropTypes.string,
  offText: PropTypes.string,
  on: PropTypes.number,
};

export default Toggle;

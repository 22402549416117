import React from 'react';
import styled from 'styled-components';
import Logo from 'src/assets/images/logo/ledmac-logo.svg'

import Intl from 'src/components/Intl';

import vw, { vwMobile } from 'src/styles/utils';

const Hero = () => {
  return (
     <Intl>
      {(t, { getLocaleURL, locale }) => (
    <Root>
      <ImageWrapper>
        <Image src={Logo} width="400" />
        <h1>{t.developer.hero.title}</h1>
      </ImageWrapper>

      <TextWrapper>
        <p className="body">{t.developer.hero.body}</p>
      </TextWrapper>
    </Root>
    )}
    </Intl>
  );
};

export default Hero;

const Root = styled.div`
  ${vw('padding-top', 52, 88, 148)}
  background-color: #F8F8F6;
  text-align: center;

  .caption {
    width: ${vwMobile(228)};
  }

  h1 {
    text-align: center;
    ${vw('margin-top', 35, 67, 86)}
    ${vw('margin-bottom', 32, 20)}
    font-weight: bold;
    ${vw('font-size', 13, 18)}
    ${vw('letter-spacing', 3, 7)}
    text-transform: uppercase;
    color: #4B4E52;
  }
`;

const ImageWrapper = styled.div`
  ${vw('padding-left', 16, 24, 48)}
  ${vw('padding-right', 16, 24, 48)}
`;

const Image = styled.img`
  ${vw('width', 180, 321)}
`;

const TextWrapper = styled.div`
  ${vw('padding-left', 32, 62, 207)}
  ${vw('padding-right', 32, 62, 207)}

  p {
    margin: 0;
  }
`;

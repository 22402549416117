import React from 'react';
import styled from 'styled-components';

import Intl from 'src/components/Intl';

import vw from 'src/styles/utils';
import theme from 'src/styles/theme';
import media from 'src/styles/media';

const TableHeader = () => (
  <Intl>
    {(t) => (
      <Root>
        <span className="plan">{t.floorplans.header.plan}</span>
        <span className="type">{t.floorplans.header.type}</span>
        <span className="sqft">{t.floorplans.header.sqft}</span>
      </Root>
    )}
  </Intl>
);

const Root = styled.div`
  display: none;
  font-family: ${theme.font.family};
  font-style: italic;
  font-weight: normal;
  line-height: 22px;
  ${vw('font-size', 18)};
  ${vw('padding-bottom', 16)}
  ${vw('margin-left', 12, 24, 0)}
  ${vw('margin-right', 12, 24, 0)}

  color: ${theme.color.cc_grey};
  align-items: center;
  justify-content: space-between;

  @media ${media.tablet} {
    display: flex;
  }

  span {
    text-align: left;

    &.plan {
      width: 28%;
      text-align: center;
    }

    &.type {
      width: 48%;
    }

    &.sqft {
      width: 24%;
    }
  }
`;

export default TableHeader;

import React from 'react';
import styled from 'styled-components';

import Hero from './Hero';
import Slick from './Slick';
import Companies from './Companies';
import data from 'src/data/developer';

import vw from 'src/styles/utils';

const Developer = () => {
  return (
    <Root>
      <Hero />
      <Slick images={data} />
      <Companies />
    </Root>
  );
};

export default Developer;

const Root = styled.main`
  background-color: #F8F8F6;
  ${vw('padding-top', 52, 80, 120)}
  ${vw('padding-bottom', 60, 70)}
`;

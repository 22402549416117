/* eslint-disable quote-props */
export default {
  a: {
    id: 'a',
    display: 'a',
    bed: 1,
    bath: 1,
    indoor: '613-621',
    outdoor: '110-115',
    sqft: '613-621',
    den: true,
    adaptable: false,
    levels: ['2', '3', '4', '5', '6'],
    type: 'one bedroom & den',
    typeId: 'oneBedOneDen',
  },
  a1: {
    id: 'a1',
    display: 'a1',
    unit: '108, 110, 112, 114',
    bed: 1,
    bath: 1,
    indoor: '622-627',
    outdoor: '196',
    sqft: '818-823',
    den: true,
    adaptable: false,
    levels: ['1'],
  },
  a2_104: {
    id: 'a2_104',
    display: 'a2',
    unit: 104,
    bed: 1,
    bath: 1,
    indoor: '630',
    outdoor: '286',
    sqft: '916',
    den: true,
    adaptable: false,
    levels: ['1'],
  },
  a2: {
    id: 'a2',
    display: 'a2',
    bed: 1,
    bath: 1,
    indoor: '630',
    outdoor: '0',
    sqft: '630',
    den: true,
    adaptable: false,
    levels: ['2', '3', '4', '5', '6'],
    type: 'one bedroom & den',
    typeId: 'oneBedOneDen',
  },
  a3_122: {
    id: 'a3_122',
    display: 'a3',
    unit: 122,
    bed: 1,
    bath: 1,
    indoor: '605',
    outdoor: '137',
    sqft: '742',
    den: false,
    adaptable: false,
    levels: ['1'],
  },
  'b_217-218': {
    id: 'b_217-218',
    display: 'b',
    unit: '217, 218',
    bed: 2,
    bath: 2,
    indoor: '771-779',
    outdoor: '84-90',
    sqft: '855-869',
    den: false,
    adaptable: true,
    levels: ['2'],
  },
  b: {
    id: 'b',
    display: 'b',
    bed: 2,
    bath: 2,
    indoor: '771-779',
    outdoor: '84',
    sqft: '855-863',
    den: false,
    adaptable: true,
    levels: ['3', '4', '5', '6'],
    type: 'two bedroom',
    typeId: 'twoBed',
  },
  'b1_209-211-213': {
    id: 'b1_209-211-213',
    display: 'b1',
    unit: '209, 211, 213',
    bed: 2,
    bath: 2,
    indoor: '779-786',
    outdoor: '84',
    sqft: '863-870',
    den: false,
    adaptable: false,
    levels: ['2'],
  },
  b1: {
    id: 'b1',
    display: 'b1',
    bed: 2,
    bath: 2,
    indoor: '779-786',
    outdoor: '84',
    sqft: '863-870',
    den: false,
    adaptable: false,
    levels: ['3', '4', '5', '6'],
    type: 'two bedroom',
    typeId: 'twoBed',
  },
  'b2_102-103': {
    id: 'b2_102-103',
    display: 'b2',
    unit: '102, 103',
    bed: 2,
    bath: 2,
    indoor: '854',
    outdoor: '315',
    sqft: '1,169',
    den: false,
    adaptable: true,
    levels: ['1'],
  },
  'b2_202-203': {
    id: 'b2_202-203',
    display: 'b2',
    unit: '202, 203',
    bed: 2,
    bath: 2,
    indoor: '854',
    outdoor: '98-105',
    sqft: '952-959',
    den: false,
    adaptable: true,
    levels: ['2'],
  },
  b2: {
    id: 'b2',
    display: 'b2',
    bed: 2,
    bath: 2,
    indoor: '854',
    outdoor: '98-105',
    sqft: '952-959',
    den: false,
    adaptable: true,
    levels: ['3', '4', '5', '6'],
  },
  'b3_109-111-113': {
    id: 'b3_109-111-113',
    display: 'b3',
    unit: '109, 111, 113',
    bed: 2,
    bath: 2,
    indoor: '834-841',
    outdoor: '143',
    sqft: '977-984',
    den: false,
    adaptable: false,
    levels: ['1'],
  },
  b4_101: {
    id: 'b4_101',
    display: 'b4',
    unit: '101',
    bed: 2,
    bath: 2,
    indoor: '787',
    outdoor: '279',
    sqft: '1,066',
    den: false,
    adaptable: false,
    levels: ['1'],
  },
  b4: {
    id: 'b4',
    display: 'b4',
    bed: 2,
    bath: 2,
    indoor: '787',
    outdoor: '93',
    sqft: '880',
    den: false,
    adaptable: false,
    levels: ['2', '3', '4', '5', '6'],
  },
  b5: {
    id: 'b5',
    display: 'b5',
    bed: 2,
    bath: 2,
    indoor: '813',
    outdoor: '108',
    sqft: '921',
    den: false,
    adaptable: false,
    levels: ['2', '3', '4', '5', '6'],
  },
  b6_120: {
    id: 'b6_120',
    display: 'b6',
    unit: 120,
    bed: 2,
    bath: 2,
    indoor: '753',
    outdoor: '228',
    sqft: '981',
    den: false,
    adaptable: false,
    levels: ['1'],
  },
  b6: {
    id: 'b6',
    display: 'b6',
    bed: 2,
    bath: 2,
    indoor: '753',
    outdoor: '134',
    sqft: '887',
    den: false,
    adaptable: false,
    levels: ['2', '3', '4'],
  },
  b7_105: {
    id: 'b7_105',
    display: 'b7',
    unit: 105,
    bed: 2,
    bath: 2,
    indoor: '830',
    outdoor: '315',
    sqft: '1,145',
    den: false,
    adaptable: true,
    levels: ['1'],
  },
  b7: {
    id: 'b7',
    display: 'b7',
    bed: 2,
    bath: 2,
    indoor: '830',
    outdoor: '98',
    sqft: '928',
    den: false,
    adaptable: true,
    levels: ['2', '3', '4', '5', '6'],
  },
  c: {
    id: 'c',
    display: 'c',
    bed: 2,
    bath: 2,
    indoor: '814',
    outdoor: '110-112',
    sqft: '924-926',
    den: false,
    adaptable: false,
    levels: ['5', '6'],
  },
  c1_119: {
    id: 'c1_119',
    display: 'c1',
    unit: 119,
    bed: 2,
    bath: 2,
    indoor: '818',
    outdoor: '649',
    sqft: '1,467',
    den: false,
    adaptable: false,
    levels: ['1'],
  },
  'c1_219-319': {
    id: 'c1_219-319',
    display: 'c1',
    unit: '219, 319',
    bed: 2,
    bath: 2,
    indoor: '818',
    outdoor: '302',
    sqft: '1,120',
    den: false,
    adaptable: false,
    levels: ['2', '3'],
  },
  c1: {
    id: 'c1',
    display: 'c1',
    bed: 2,
    bath: 2,
    indoor: '818',
    outdoor: '302',
    sqft: '1,120',
    den: false,
    adaptable: false,
    levels: ['4'],
  },
  c2_121: {
    id: 'c2_121',
    display: 'c2',
    unit: 121,
    bed: 2,
    bath: 2,
    indoor: '810',
    outdoor: '384',
    sqft: '1,194',
    den: false,
    adaptable: false,
    levels: ['1'],
  },
  c2: {
    id: 'c2',
    display: 'c2',
    bed: 2,
    bath: 2,
    indoor: '811',
    outdoor: '192',
    sqft: '1,003',
    den: false,
    adaptable: false,
    levels: ['2', '3', '4'],
  },
  c3_106: {
    id: 'c3_106',
    display: 'c3',
    unit: 106,
    bed: 2,
    bath: 2,
    indoor: '827',
    outdoor: '387',
    sqft: '1,214',
    den: false,
    adaptable: false,
    levels: ['1'],
  },
  c3: {
    id: 'c3',
    display: 'c3',
    bed: 2,
    bath: 2,
    indoor: '827',
    outdoor: '127',
    sqft: '954',
    den: false,
    adaptable: false,
    levels: ['2', '3', '4', '5', '6'],
    type: 'two bedroom',
    typeId: 'twoBed',
  },
  'd_117-118': {
    id: 'd_117-118',
    display: 'd',
    unit: '117, 118',
    bed: 2,
    bath: 2,
    indoor: '838-841',
    outdoor: '224',
    sqft: '1,062-1,065',
    den: true,
    adaptable: false,
    levels: ['1'],
  },
  e_115: {
    id: 'e_115',
    display: 'e',
    unit: 115,
    bed: 3,
    bath: 2,
    indoor: '928',
    outdoor: '663',
    sqft: '1,591',
    den: false,
    adaptable: false,
    levels: ['1'],
  },
  e: {
    id: 'e',
    display: 'e',
    bed: 3,
    bath: 2,
    indoor: '928-930',
    outdoor: '336',
    sqft: '1,264-1,266',
    den: false,
    adaptable: false,
    levels: ['2', '3', '4', '5', '6'],
  },
  e1_207: {
    id: 'e1_207',
    display: 'e1',
    unit: 207,
    bed: 3,
    bath: 2,
    indoor: '928-930',
    outdoor: '127',
    sqft: '1,027',
    den: false,
    adaptable: false,
    levels: ['2'],
  },
  e1: {
    id: 'e1',
    display: 'e1',
    bed: 3,
    bath: 2,
    indoor: '900-901', // need to verify
    outdoor: '127',
    sqft: '1,027-1,028',
    den: false,
    adaptable: false,
    levels: ['3', '4', '5', '6'],
    type: 'three bedroom',
    typeId: 'threeBed',
  },
  e2_107: {
    id: 'e2_107',
    display: 'e2',
    unit: 107,
    bed: 3,
    bath: 2,
    indoor: '954',
    outdoor: '271',
    sqft: '1,225',
    den: false,
    adaptable: false,
    levels: ['1'],
  },
  e3: {
    id: 'e3',
    display: 'e3',
    bed: 3,
    bath: 2,
    indoor: '928',
    outdoor: '116',
    sqft: '1,044',
    den: false,
    adaptable: false,
    levels: ['5', '6'],
  },
  '2_a': {
    id: '2_a',
    display: 'a',
    bed: 1,
    bath: 1,
    indoor: '608-619',
    outdoor: '112-127',
    sqft: '720-746',
    den: true,
    adaptable: false,
    levels: ['2', '3', '4', '5', '6'],
    type: 'one bedroom & den',
    typeId: 'oneBedOneDen',
  },
  '2_a2': {
    id: '2_a2',
    display: 'a2',
    bed: 1,
    bath: 1,
    indoor: '639',
    outdoor: '152',
    sqft: '791',
    den: true,
    adaptable: false,
    levels: ['2', '3', '4'],
    type: 'one bedroom & den',
    typeId: 'oneBedOneDen',
  },
  '2_b': {
    id: '2_b',
    display: 'b',
    bed: 2,
    bath: 2,
    indoor: '774-794',
    outdoor: '84-98',
    sqft: '858-892',
    den: false,
    adaptable: true,
    levels: ['2', '3', '4', '5', '6'],
    type: 'two bedroom',
    typeId: 'twoBed',
  },
  '2_b1': {
    id: '2_b1',
    display: 'b1',
    bed: 2,
    bath: 2,
    indoor: '779-786',
    outdoor: '84',
    sqft: '863-870',
    den: false,
    adaptable: false,
    levels: ['3', '4', '5', '6'],
    type: 'two bedroom',
    typeId: 'twoBed',
  },
  '2_c1': {
    id: '2_c1',
    display: 'c1',
    bed: 2,
    bath: 2,
    indoor: '818-821',
    outdoor: '300-302',
    sqft: '1,118-1,123',
    den: false,
    adaptable: false,
    levels: ['2', '3', '4'],
    type: 'two bedroom',
    typeId: 'twoBed',
  },
  '2_e2': {
    id: '2_e2',
    display: 'e2',
    bed: 2,
    bath: 2,
    indoor: '908',
    outdoor: '125',
    sqft: '1,033',
    den: false,
    adaptable: false,
    levels: ['2', '3', '4', '5', '6'],
    type: 'three bedroom',
    typeId: 'threeBed',
  },
};

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Button = (props) => {
  const { text, handleClick, href } = props;
  return (
    <Root
      href={href}
      className="button button-ghost"
      onClick={handleClick}
      {...props}
    >
      {text}
    </Root>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  handleClick: PropTypes.func,
};

const Root = styled.a`
  margin: 0;
  letter-spacing: 3px;
  width: 100%;
  ${vw('padding-top', 6, 10)}
  ${vw('padding-bottom', 6, 10)}
  ${vw('padding-left', 12, 40)}
  ${vw('padding-right', 12, 40)}
  font-weight: 500;

  @media ${media.tablet} {
    font-weight: 600;
  }

  @media ${media.desktop} {
    width: unset;
  }
`;

export default Button;

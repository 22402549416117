import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Button = (props) => {
  const { text, to } = props;
  return (
    <Root to={to} className={`button button-ghost`} {...props}>
      {text}
    </Root>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  to: PropTypes.string,
};

const Root = styled(Link)``;

export default Button;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Button = (props) => {
  const { text, href } = props;
  return (
    <Root href={href} className="button button-ghost" {...props}>
      {text}
    </Root>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  href: PropTypes.string,
  handleClick: PropTypes.func,
};

const Root = styled.a`
  margin: 0;
`;

export default Button;
